import React from "react";
import * as moment from "moment";

import * as momentTimezone from "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";

import DaySection from "./DaySection";
import { EventViewModal } from "../../../components/modals/eventScheduler/eventViewModal";

import { Typography } from "@material-ui/core";

import { ColorTheme } from '../../../utils/colorTheme';

const useStyles = makeStyles({
  root: {
    display: "flex",
    minWidth: "80%",
    flexDirection: "column",
  },
  titleBox: {
    display: "inline-flex",
    alignItems: "center",
    height: 100,
    width: "100%",
    justifyContent: "flex-end",
  },
  weekListing: {
    textTransform: "uppercase",
    color: ColorTheme.blue4.b,
    lineHeight: 1.7,
    fontSize: 14,
    fontWeight: 500,
    margin: '22px 5px 5px 5px',
    fontFamily: "'Roboto Mono', monospace",

    "&:first-child": {
      marginTop: 0
    }
  }
});

const applyFilters = (events, filters) => {
  const { programIds, showPublic } = filters;
  let newEvents = [...events];

  newEvents = newEvents.filter(
    (e) =>
      e.isPublic || e.prepopulatedByProgramType ||
      (!!e.programs &&
        e.programs.filter((x) => programIds.includes(x.programId)).length > 0)
  );

  if (!showPublic) {
    newEvents = newEvents.filter((el) => !el.isPublic);
  }

  return newEvents;
};

const getCurrDate = (dateObj) => {
  var month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  var date = ("0" + dateObj.getDate()).slice(-2);
  var year = dateObj.getFullYear();
  return year + "-" + month + "-" + date;
};

const today = moment().format("YYYY-MM-DD");
const currentWeek = parseInt(moment().format('w'), 10);
const currentYear = parseInt(moment().year(), 10);

const calculateEventStatus = (week, year) => {
  return year === currentYear
    ? week === currentWeek 
      ? 0 //current
      : week > currentWeek 
        ? 1 //future
        : -1 //past
    : year > currentYear
      ? 1 //future
      : -1; //past
}

const EventDataGrid = ({
  user,
  events,
  selectedDate,
  userTimeZone,
  programs,
  programColors,
  filters,
  updateEventData,
  deleteEvent,
  labels,
  loadEventAdditionalInfo,
  isLoadingSingleEventDetails,
  loadProgramMembers,
  programMembers,
  isLoadingProgramMembers,
  push,
  isFacilitator,
  setCloseCreateEventModal,
  isClosedCreateEventModal,
  setEscapeConfirmDialog,
  setEventViewModal,
  eventViewModal,
  setCurrentItemIndex,
  currentItemIndex
}) => {
  const classes = useStyles();


  const currentDate = getCurrDate(selectedDate);

  const eventList = applyFilters(events, filters).map((e) => {

    let convertedStartDate;
    let convertedEndDate;

    //Repeated events created from UI
    if (!!e.originStartDate) {
      //Get timezone offset in the date of event start.
      //We need it to have the same time after summer/winter time switching.
      const utcOriginStartDate = momentTimezone(e.originStartDate)
        .clone().tz(userTimeZone);
      const timezoneOffset = utcOriginStartDate.utcOffset() / 60;

      // Convert start and end date to user timezone;
      convertedStartDate = moment.utc(e.startDate).add(timezoneOffset, 'hours');
      convertedEndDate = moment.utc(e.endDate).add(timezoneOffset, 'hours');
    } else {
      convertedStartDate = momentTimezone(e.startDate).clone().tz(userTimeZone);
      convertedEndDate = momentTimezone(e.endDate).clone().tz(userTimeZone);
    }

    const localStartDate = convertedStartDate.format("YYYY-MM-DD");
    const localStartTime = convertedStartDate.format("h:mm A");
    const localEndDate = convertedEndDate.format("YYYY-MM-DD");
    const localEndTime = convertedEndDate.format("h:mm A");
    return {
      convertedStartDate,
      convertedEndDate,
      localStartDate,
      localStartTime,
      localEndDate,
      localEndTime,
      ...e,
    };
  });

  const groups = eventList.reduce((groups, item) => {
    if (!groups[item.localStartDate]) {
      groups[item.localStartDate] = [];
    }
    groups[item.localStartDate].push(item);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups)
    .filter((x) => x >= currentDate)
    .map((date) => {
      return {
        date,
        items: groups[date],
      };
    })
    .sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });
  
  const groupByWeek = groupArrays.reduce((val, eventsGroupByDay) => {
    const date = moment(eventsGroupByDay.date, 'YYYY-MM-DD');
    const week = parseInt(date.format('w'), 10);
    const weekStartDate = date.day(0);
    const yearStart = weekStartDate.year();
    const weekFormat = `${yearStart}-${week}`;
    if (!val[weekFormat]) {
      const weekEndDate = moment(weekStartDate).add(6, 'd');
      val[weekFormat] = {
        day: weekStartDate.date(),
        week: week,
        month: weekStartDate.month(),
        year: yearStart,
        yearEnd: weekEndDate.year(),
        startDate: weekStartDate.format("MMMM D"),
        endDate: weekEndDate.format("MMMM D"),
        status: calculateEventStatus(week, yearStart),
        events: []
      }
    }
    val[weekFormat].events.push(eventsGroupByDay);
    return val;
  }, {});

  const groupByWeekArray = Object.keys(groupByWeek)
    .map((week) => {
      return {
        ...groupByWeek[week]
      };
    })
    .sort(function (a, b) {
      return new Date(a.year, a.month, a.day) - new Date(b.year, b.month, b.day);
    });

  return (
    <>
      <div className={classes.root} sel="event">
        {currentItemIndex >= 0 &&
          !!eventList &&
          currentItemIndex <= eventList.length && !!eventList[currentItemIndex] && (
            <EventViewModal
              user={user}
              event={eventList[currentItemIndex]}
              timezone={userTimeZone}
              isOpen={eventViewModal}
              updateEvent={updateEventData}
              programs={programs}
              deleteEvent={deleteEvent}
              onCloseModal={(event) => {
                if (event?.allowClose) {
                  setEventViewModal(false);
                  setCurrentItemIndex(-1);
                  return;
                }
                if (event?.key === "Escape" && isClosedCreateEventModal ||
                  isClosedCreateEventModal) {
                    setEscapeConfirmDialog(true);
                    return;
                }
                setEventViewModal(false);
                setCurrentItemIndex(-1);
              }}
              loadProgramMembers={loadProgramMembers}
              isLoadingSingleEventDetails={isLoadingSingleEventDetails}
              programMembers={programMembers}
              isLoadingProgramMembers={isLoadingProgramMembers}
              push={push}
              isFacilitator={isFacilitator}
              setCloseCreateEventModal={setCloseCreateEventModal}
              isClosedCreateEventModal={isClosedCreateEventModal}
              setEscapeConfirmDialog={setEscapeConfirmDialog}
            />
          )}

        {groupByWeekArray.map((week, ind) =>
          <>
            <Typography className={classes.weekListing}>
              {`${week.status === 1 
                  ? "Upcoming Events " 
                  : ""}${week.startDate}${week.year != week.yearEnd ? `, ${week.year}` : ""} - ${week.endDate}, ${week.yearEnd}`} 
            </Typography>
            {week.events.map((e, index) => {
            return (
              <DaySection
                key={`${index}-${ind}`}
                item={e}
                isToday={ind > 0 || index > 0 ? today === e.date : false}
                programColors={programColors}
                programs={programs}
                labels={labels}
                onClickView={(item) => {
                  const index = eventList.findIndex(
                    (x) => x.id === item.id && x.startDate === item.startDate
                  );

                  if (index >= 0) {
                    // Load additional info if doesn't exist only;
                    if (!item.attendees) {
                      loadEventAdditionalInfo(item);
                    }

                    setCurrentItemIndex(index);
                    setEventViewModal(true);
                  }
                }}
              />
            );
          })}
        </>
        )}
        {!groupArrays.length && (
          <DaySection
            isEmptyState={true}
            programColors={programColors}
            programs={programs}
            selectedDate={selectedDate}
            sel="event"
          />
        )}
      </div>
    </>
  );
};

export default EventDataGrid;
