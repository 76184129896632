import React, { useState, useEffect, useMemo, useLayoutEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import * as moment from 'moment';

import { push as pushToRoute } from 'connected-react-router';

import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import * as contentfulActions from '../../actions/contentful';
import * as programActions from '../../actions/program';
import * as announcements from '../../actions/announcements';
import * as globalActions from '../../actions/global';

import Stepper from '@material-ui/core/Stepper';
import Box from '@material-ui/core/Box';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from "@material-ui/core/Grid";

import * as colors from '../../theme/colors';
import { connectTo } from '../../utils/generic';
import { useTitle } from '../../utils/pageTitles';
import CustomButton from '../../components/customButton/customButton';
import PageTitle from "../../components/page-title/pageTitle";
import Footer from "../../containers/Footer/footer";
import TextCustomButton from '../../components/text-button/text-button';
import arrowBackIcon from "../../images/arrow-back-icon.svg";


import FirstStep from './renewalSteps/FirstStep';
import SecondStep from './renewalSteps/SecondStep';
import ThirdStep from './renewalSteps/ThirdStep';
import ThankYouPage from './renewalSteps/ThankYou';

import * as programsApi from '../../api/programs';
import { isValidEmail } from '../../utils/generic';
import { PageLeaveModal } from '../../components/modals/pageLeaveWarning';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  main: {
    width: "100%",
    margin: "auto auto",
    height: "100%",
    minHeight: 'calc(100vh - 135px)',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  navBackArrow: {
    backgroundImage: `url(${arrowBackIcon})`,
    display: "inline-block",
    height: "40px",
    transition: "transform 0.3s",
    width: "20px",
    color: theme.palette.primary.main,
  },
  pageContainer: {
    margin: "auto auto",
    padding: "20px 0",
    justifyContent: "center",
    maxWidth: "600px",
  },
  childContainer: {
    display: "flex",
    alignItems: "center",
  },
  childBox: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
  },
  imageContainer: {
    marginRight: 5,
  },
  initials: {
    color: colors.blue900,
    fontSize: 14,
  },
  titleBox: {
    display: 'flex',
    justifyContent: "center"
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: "100%",
    borderRadius: 2,
    background: colors.white,
    boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.2)",
  },
  form: {
    paddingTop: 20,
  },
  formGroup: {
    height: '90%',
  },
  frame: {
    border: '0',
    height: '100%',
    width: '100%',
  },
  buttonGroup: {
    marginTop: '30px',
    height: 42,
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    boxShadow: 'none',
  },
  button: {
    borderRadius: 5,
    fontSize: 14,
    fontWeight: 'bold',
    backgroundColor: colors.grey400,
    color: 'white',
    margin: '15px',
    '&:hover': {
      backgroundColor: colors.grey500,
    },
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnContainer: {
    marginTop: 20,
    fontSize: 14,
  },
  stepWrapper: {
    '& .MuiPaper-root': {
      marginBottom: 19,
      backgroundColor: 'inherit',
    },
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      color: colors.darkThemeGreen,
    },
    '& .MuiStepIcon-active': {
      color: colors.darkThemeGreen,
    },
    '& .MuiStepLabel-label': {
      fontSize: 14,
    }
  },
  submitButtonBox: {
    marginLeft: "auto",
    padding: "0 24px 24px 0",
    fontSize: 16,
    '& .MuiButton-text': {
      fontSize: 16,
    },
    '& .MuiButton-startIcon': {
      margin: 0,
    },
  },
  backBtn: {
    color: colors.darkBlueGrey,
    '&:focus': {
      color: colors.darkThemeGreen,
    },
  },
}));

const getCurrentPage = (
  page,
  user,
  renewalLabels,
  program,
  minDate,
  maxDate,
  isDecisionMaker,
  setShowSignature,
  showSignature,
  setSignAgreementMode,
  signAgreementMode,
  isLoading,
  values,
  isEditMode,
  setIsEditMode,
  setValues,
  errors,
  setTouched,
) => {
  switch (page) {
    case 0:
      return (
        <FirstStep
          labels={renewalLabels}
          user={user}
          program={program}
          isLoading={isLoading}
        />
      );
    case 1:
      return (
        <SecondStep
          labels={renewalLabels}
          minDate={minDate}
          maxDate={maxDate}
          countryId={user?.countryId}
          values={values}
        />
      );
    case 2:
      return (
        <ThirdStep
          labels={renewalLabels}
          isDecisionMaker={isDecisionMaker}
          showSignature={showSignature}
          setShowSignature={setShowSignature}
          setSignAgreementMode={setSignAgreementMode}
          signAgreementMode={signAgreementMode}
          program={program}
          user={user}
          values={values}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          setValues={setValues}
          errors={errors}
          setTouched={setTouched}
        />
      );
    default:
      return <ThankYouPage user={user} labels={renewalLabels} />;
  }
};

const invalidDateMsg =
  'Please review the Launch date you provided and make sure it falls within the dates specified.';

const getValidationSchema = (page, minDate, maxDate) => {
  const obj = {};

  if (page >= 1) {
    obj.launchDate = Yup.date()
      .min(new Date(minDate), invalidDateMsg)
      .max(new Date(maxDate), invalidDateMsg)
      .required(invalidDateMsg)
      .typeError(invalidDateMsg);
  }

  if (page === 2) {
    obj.dmLastName = Yup.string().required("Last name is required");
    obj.dmFirstName = Yup.string().required("First name is required");
    obj.dmEmail = Yup.string().required("Email is required").test('isValid', "Email invalid", (value) => !isValidEmail(value));
  }

  return Yup.object(obj);
};

const getAgeGroupsServed = (ageGroupServed) => {
  switch (ageGroupServed) {
    case '6th-12th':
      return 'Middle (6-8);High (9-12)';
    case '3rd-5th':
      return 'Elementary (3-5)';
    default:
      return ageGroupServed;
  }
}

const RenewClub = ({
  loadGlobalContent,
  contentful,
  renewProgram,
  user,
  push,
  match: {
    params: { code },
  },
  history,
}) => {
  useTitle('renew-club');

  const classes = useStyles();
  
  const [renewingStarted, setRenewingStarted] = useState(false);
  const [page, setPage] = useState(0);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [program, setProgram] = useState({});
  const [isDecisionMaker, setIsDecisionMaker] = useState(false);
  const [showSignature, setShowSignature] = useState(false);
  const [signAgreementMode, setSignAgreementMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);
  const [isDataChanged, setDataChanged] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);

  const ref = useRef(null);
  const unblockRef = useRef(null);

  useEffect(() => {
    if (!code) {
      returnToHome();
    }
  }, [code]);

  useEffect(() => {
    // dont load contentfull if it was already loaded
    if (!contentful || !!contentful.hqConfig) {
      return null;
    }
    loadGlobalContent();
  }, []);


  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView(true);
    }
  }, [page]);

  useEffect(() => {
    setIsLoading(true);
    programsApi
      .getProgramByCode({ programCode: code })
      .then(({ data: hqProgram }) => {
        if (!renewingStarted && !isRenewalAvailable(hqProgram)) {
          returnToHome();
          return;
        }

        setProgram(hqProgram);

        const dm = hqProgram.decisionMaker;
      if (!!dm && dm.id && !!user?.id) {
        const isDM = dm.email === user.email || dm.sfUserId === user.sfUserId;
        setIsDecisionMaker(isDM);
        setShowSignature(isDM);
      }
      setIsLoading(false);
      setRenewingStarted(true);
    }).catch(returnToHome);
  }, [code,user]);

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (page === 3) {
        setEscapeConfirmDialog(false);
        setDataChanged(false);
        return;
      }

      setNextLocation(location);
      setEscapeConfirmDialog(true);

      return !isDataChanged;
    });

    return () => {
      unblockRef.current && unblockRef.current();
  };
  }, [isDataChanged, isEscapeConfirmDialog]);

  // scroll to top after page changing
  useLayoutEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  },[page])


  const returnToHome = () => {
    setDataChanged(false);
    push('/home');
  }

  const onBackClick = () => {
    if (!page) {
      return;
    }

    setPage(page - 1);
    setDisabledSubmit(false);
  };

  const isRenewalAvailable = (p) => {
    if (!p || p.programType === "SIP" || p.programType === "CAMPUS"
      || !!p.renewalFormSubmitted) {
      return false;
    }
    return true;
  }

  const currentDate = moment(new Date());
  let minDate;

  // If it is April, we should set the minDate to May 1, otherwise we should add 2 days to the currentDate
  // p.s. month counting starting from 0
  if (currentDate.month() === 3) {
    minDate = moment(new Date(`May 1, ${currentDate.year()}`)).format('MM/DD/YYYY');
  } else {
    minDate = moment(currentDate, 'DD-MM-YYYY')
      .add(2, 'days')
      .format('MM/DD/YYYY');
  }

  const maxDate = moment(currentDate).add(10, 'months').format('MM/DD/YYYY');
  
  const verifyStepData = (values, page, isDecisionMaker, isAnonymousProgram) => {
    const {
      conditions,
      autorizedToSign,
      clubParticipationAgreement,
      initials,
      signature,
      notSignedAgreementMsg,
      curriculumType,
      dmLastName,
      dmFirstName,
      dmEmail,
      ageGroupServed,
    } = values;
    if (page === 0) {
      setDisabledSubmit(!conditions);
      return;
    }

    if (page === 1) {
      setDisabledSubmit(!ageGroupServed.trim() || !curriculumType.trim());
      return;
    }

    if (page === 2) {
      if (isAnonymousProgram) {
        setDisabledSubmit(false);
        return;
      }

      if(autorizedToSign !== null && !showSignature || !autorizedToSign && notSignedAgreementMsg.length || signature) {
        setDataChanged(true);
      }

      if (autorizedToSign || isDecisionMaker) { 
        if (clubParticipationAgreement) {
          const isSubmitDisabled =
            !initials.length ||
            !signature.length ||
            isEditMode ||
            !dmLastName.length ||
            !dmFirstName.length ||
            !dmEmail.length;

          setDisabledSubmit(isSubmitDisabled);
          return;
        }
        setDisabledSubmit(!notSignedAgreementMsg.length);
        return;
      }

      // unblock submitBtn if user selected "My DM will sign the agreement"
      setDisabledSubmit(autorizedToSign === null);
    }

    if (page === 3) {
      setEscapeConfirmDialog(false);
      setDataChanged(false);
      return;
    }
  };

  const initialValues = useMemo(() => {
    const dmFullName = program?.decisionMaker?.fullName?.split(' ');

    return {
    conditions: true,
    launchDate: new Date(minDate),
    autorizedToSign: null,
    clubParticipationAgreement: true, // set up true by default for decision maker
    initials: "",
    signature: "",
    notSignedAgreementMsg: "",
    curriculumType: program?.sfCurriculumType || ' ',
    dmFirstName: dmFullName ? dmFullName[0] : '',
    dmLastName: dmFullName ? dmFullName[1] : '',
    dmEmail: program?.decisionMaker?.email || '',
    ageGroupServed: !!program?.ageGroupServed ? getAgeGroupsServed(program.ageGroupServed) : '',
  }
  },[program])

  if (!contentful?.GlobalComponentQuery?.renewalLabels) {
    return null;
  }

  const {
    GlobalComponentQuery: { renewalLabels },
  } = contentful;

  const steps = [
    renewalLabels.firstStepLabel,
    renewalLabels.secondStepLabel,
    renewalLabels.thirdStepLabel,
  ];

  const isAnonymousProgram = program && program.anonymousUser;

  if (!code || !program) {
    return null;
  }

  return (
    <React.Fragment>
      <main className={classes.main}>
        <Grid
          item
          xs={12}
          md={7}
          className={classes.pageContainer}
        >
          <Box>
            <Box>
              <TextCustomButton
                mode="primary"
                startIcon={<KeyboardArrowLeftIcon />}
                onClick={() => push("/home")}
                label='Back to My Programs'
              />
            </Box>
            <PageTitle title="Renew the club" />
            <Box className={classes.wrapper}>
              <Formik
                enableReinitialize={true}
                validateOnMount={true}
                initialValues={initialValues}
                validationSchema={getValidationSchema(page, minDate, maxDate)}
                validate={(values) => {
                  let errors = {};
                  verifyStepData(values, page, isDecisionMaker, isAnonymousProgram);
                  return errors;
                }}
                onSubmit={(values) => {
                  setPage(page + 1);
                  verifyStepData(values, page + 1, isDecisionMaker, isAnonymousProgram);
                  const { clubParticipationAgreement, autorizedToSign } = values;

                  if (page === 2) {
                    renewProgram({
                      programId: program.id,
                      data: {
                        ...values,
                        launchDate: moment(values.launchDate).format('YYYY-MM-DD'),
                        clubParticipationAgreement:
                          !isAnonymousProgram && (isDecisionMaker || signAgreementMode)
                            ? clubParticipationAgreement
                            : null,
                        autorizedToSign: !isDecisionMaker ? autorizedToSign : null,
                      },
                      userId: user.id,
                    });
                    if (!clubParticipationAgreement) {
                      returnToHome();
                    }
                  }
                }}
              >{({ values, setValues, errors, dirty, setTouched }) => {
                setDataChanged(dirty);
                return (
                  <Form className={classes.form} ref={ref}>
                    <Box className={classes.formGroup}>
                      <Box className={classes.stepWrapper}>
                        <Stepper activeStep={page} alternativeLabel={true}>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel> {label} </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Box>
                      {getCurrentPage(
                        page,
                        user,
                        renewalLabels,
                        program,
                        minDate,
                        maxDate,
                        isDecisionMaker,
                        setShowSignature,
                        showSignature,
                        setSignAgreementMode,
                        signAgreementMode,
                        isLoading,
                        values,
                        isEditMode,
                        setIsEditMode,
                        setValues,
                        errors,
                        setTouched,
                      )}
                    </Box>
                    <Box className={classes.btnContainer}>
                      <Box className={classes.btnWrapper}>
                        <Box className={classes.submitButtonBox}>
                          {!!page && page < 3 && (
                            <TextCustomButton
                              className={classes.backBtn}
                              mode="primary"
                              startIcon={<KeyboardArrowLeftIcon />}
                              onClick={() => {
                                setNextLocation();
                                unblockRef.current = null;
                                onBackClick();
                              }}
                              label={'Back'}
                            />
                          )}
                          {!isLoading && page < 3 && (
                            <CustomButton
                              mode="primary"
                              disabled={isEditMode || disabledSubmit}
                              type="submit"
                              label={page < 2 ? 'Next' : 'Submit'}
                            />
                          )}
                          {page === 3 && (
                            <CustomButton
                              mode="primary"
                              onClick={returnToHome}
                              label={'Done'}
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Form>
                )
              }}
              </Formik>
            </Box>
          </Box>
        </Grid>
      </main>
      <Footer />
      <PageLeaveModal
        onCancel={() => { setEscapeConfirmDialog(false) }}
        onConfirm={() => {
          setEscapeConfirmDialog(false);
          if (unblockRef && nextLocation) {
            unblockRef.current();
            history.push(nextLocation);
            return;
          }
          onBackClick();
        }}        
        open={isEscapeConfirmDialog}
      />
    </React.Fragment>
  );
};

export default connectTo(
  ({ announcements, auth: { user }, contentful }) => ({
    announcements,
    contentful,
    user,
  }),
  {
    ...programActions,
    ...announcements,
    ...globalActions,
    ...contentfulActions,
    push: pushToRoute,
  },
  RenewClub
);
