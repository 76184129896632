import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import ProgramCard from "./program";
import RenewalModal from "../../../components/modals/renewal/Renewal";
import { Grid, Typography, Box } from "@material-ui/core";
import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";
import UserDOBModal from '../../../components/modals/userProfile/userDOBModal';
import PageTitle from "../../../components/page-title/pageTitle";
import userTypes from "../../../constants/userTypeConstants";
import { PROGRAM_TYPES as programTypes } from '../../../utils/constants';
import Roles from "../../../utils/permissions";
import { arrayToObject } from "../../../utils/generic";
import { userIsAdult, userHasValidBirthday } from "../../../utils/userUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 50px",
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      padding: "0 30px",
    },
  },
  label: {
    width: "fit-content",
    color: colors.darkBlue100,
    fontSize: '2.225em',
    fontWeight: 900,
    lineHeight: 1,
    textTransform: 'uppercase',
  },
  titleContainer: {
    marginBottom: 20,
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
      display: "flex",
    },
  },
  actionsBox: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 15,
    },
  },
  applyButton: {
    height: 40,
    borderRadius: 5,
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    marginRight: 5,
    "&:hover": {
      backgroundColor: colors.teal500,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: 0,
      marginLeft: 5,
    },
  },
  applyLink: {
    color: "white",
    "&:hover": {
      textDecoration: "none",
    },
  },
  joinButton: {
    height: 40,
    borderRadius: 5,
    backgroundColor: colors.blue900,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    marginRight: 5,
    "&:hover": {
      backgroundColor: colors.blue800,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  yearContainer: {
    height: 30,
    width: "100%",
    fontWeight: "bold",
    fontFamily: "'Roboto Mono',monospace",
  },
  actionBtn: {
    minWidth: '150px',
    margin: 2,
    height: 42,
  },
}));

const subheaders = {
  COMMUNITY_PARTNERSHIP: "Community Partnership",
};

const ProgramsList = ({
  currentClub,
  programs,
  completedPrograms,
  defaultProgramId,
  partnerships,
  inactiveCP,
  onSelectProgram,
  onSelectPartnership,
  onJoinNewClick,
  title,
  icons,
  showApply,
  onApplyClick,
  contentful,
  userRoles,
  user,
  internationalUser,
  push,
  programTypesById,
}) => {
  const classes = useStyles();
  const [programToRenew, setProgramToRenew] = useState(null);
  const [renewal, setRenewal] = useState(false);
  const [userDOBModal, setUserDOBModal] = React.useState(false);

  const onClick = (program, isCompleted) => {
    const { communityPartnershipDetails } = program;

    switch (communityPartnershipDetails?.programType) {
      case programTypes.COMMUNITY_PARTNERSHIP:
        return isCompleted ? null : onSelectPartnership(program);
      default:
        return onSelectProgram(program);
    }
  };

  const onRenewClick = (program) => {
    setProgramToRenew({ program });

    if (program.programType === programTypes.CLUB) {
      push(`/renew-club/${program.code}`);
    }

    if (program.programType === programTypes.COLLEGE_LOOP) {
      setRenewal(true);
    }
  };


  const isRenewalAvailable = (p) => {
    const currentProgramType = p.communityPartnershipDetails || programTypesById && programTypesById[p.programTypeId];
    if (!userRoles || !contentful || !contentful.hqConfig || !defaultProgramId || p.id === defaultProgramId || ![programTypes.CLUB, programTypes.COLLEGE_LOOP].includes(currentProgramType.programType)) {
      return false;
    }

    const {
      hqConfig: {
        collegeLoopRenewalYear,
        collegeLoopRenewalAvailable,
        activeRenewalYear,
        clubRenewalAvailable,
      },
    } = contentful;

    let isRenewalActiveYear = false;
    let isRenewalYear = false;
    const isCollegeLoop = currentProgramType.programType === programTypes.COLLEGE_LOOP;

    if (!!activeRenewalYear || !!collegeLoopRenewalYear) {
      const renewalYears = {
        start: parseInt(activeRenewalYear.split("-")[0], 10),
        end: parseInt(activeRenewalYear.split("-")[1], 10),
      };

      isRenewalYear = isCollegeLoop
        ? p.year === parseInt(collegeLoopRenewalYear.split("-")[0], 10)
        : p.year >= renewalYears.start && p.year <= renewalYears.end;

      isRenewalActiveYear = isCollegeLoop
        ? collegeLoopRenewalAvailable
        : clubRenewalAvailable;
    }

    const programRole = userRoles.find(
      (e) => e.programId === parseInt(p.id, 10)
    );
    const isFacilitator = programRole ? programRole.roleId === Roles.Facilitator : false;
    const isViewer = programRole ? programRole.roleId === Roles.Viewer : false;

    if (isViewer && isCollegeLoop) {
      return false;
    }

    return (
      !p.isInternational &&
      (isFacilitator || isViewer) &&
      isRenewalYear &&
      isRenewalActiveYear
    );
  };

  const renderProgramsSection = (completedPrograms, isCompleted, displayYear = true) => {
    let item;
    if (completedPrograms) {
      item = arrayToObject(completedPrograms, "year");
    }
    const result =
      item &&
      Object.keys(item).reverse().map((e) => {
        const programs = item[e];
        let year = parseInt(e, 10);
        let title;
        year ? title = `${year}-${++year}` : title = 'UNKNOWN';

        return (
          <React.Fragment>
            {displayYear && <Typography className={classes.yearContainer}>{title}</Typography>}
            {programs.map((p) => {
              let isRenewal = isRenewalAvailable(p);
              return (
                <ProgramCard
                  key={p.id}
                  program={p}
                  icon={renderIcon(p, programTypesById)}
                  onClick={() => onClick(p, isCompleted)}
                  isCompleted={isCompleted}
                  isRenewed={!!p.renewalFormSubmitted}
                  enableRenewal={isRenewal}
                  onRenewClick={() => onRenewClick(p)}
                  programTypesById={programTypesById}
                />
              );
            })}
          </React.Fragment>
        );
      });

    return result;
  };

  const renderIcon = (program, programTypesById) => {
    if (!icons.programIcons) {
      return "";
    }

    const { programTypeId, communityPartnershipDetails } = program;
    const programTypeDetails = communityPartnershipDetails || programTypesById[programTypeId];
    const constentfulIcons = arrayToObject(icons.programIcons, 'name');

    let icon;
    const isDefaultProgram = program.id === defaultProgramId;
    if (!isDefaultProgram && programTypeDetails?.programIcon && constentfulIcons[programTypeDetails.programIcon]) {
      icon = constentfulIcons[programTypeDetails.programIcon][0];
    } else {
      icon = isDefaultProgram ? icons.programIcon : icons.clubIcon
    }

    return icon ? icon.src : "";
  };

  const checkIsActiveProgramsSameYear = (programs, currentClub) => {
    let item;
    const allActivePrograms = currentClub ? programs?.filter(el => el.active).concat(currentClub) : programs?.filter(el => el.active).concat(partnerships);
    if (programs) {
      item = arrayToObject(allActivePrograms, "year");
      return Object.keys(item).length > 1
    }
  }

  const completedItems = renderProgramsSection(completedPrograms, true);
  const completedCpItems = renderProgramsSection(inactiveCP, true, false);
  const programItems = renderProgramsSection(programs?.filter(el => el.active), false);
  const isYears = checkIsActiveProgramsSameYear(programs, currentClub);
  const allowJoin = user.userType !== userTypes.Anonymous;

  const renderDOBModal = () => {
    return (
      <UserDOBModal
        onClose={() => {
          setUserDOBModal(false);
        }}
        userDOBModal={userDOBModal}
        callback={(user) => userIsAdult(user) && onApplyClick()}
        message='In order to apply you must be at least 18 years old.'
      />
    );
  };

  return (
    <div className={classes.root}>
      {user && userDOBModal && renderDOBModal()}
      <Box className={classes.titleContainer}>
        <PageTitle title={title} />
        <Box className={classes.actionsBox}>
          {onJoinNewClick && allowJoin && !internationalUser && (
            <CustomButton
              mode={"secondary"}
              label={"Join New"}
              onClick={onJoinNewClick}
              className={classes.actionBtn}
              sel="join-new"
            />
          )}
          {showApply && (
            <CustomButton
              className={classes.actionBtn}
              mode={"primary"}
              label={"Apply"}
              onClick={() => userHasValidBirthday(user) ? onApplyClick() : setUserDOBModal(true)}
              sel="apply"
            />
          )}
        </Box>
      </Box>
      <Grid container>
        {partnerships && !!partnerships.length &&
          partnerships.map((p) => {
            return (
              <ProgramCard
                key={p.id}
                program={p}
                subheader={subheaders.COMMUNITY_PARTNERSHIP}
                icon={renderIcon(p, programTypesById)}
                onClick={() => onClick(p)}
                programTypesById={programTypesById}
              />
            );
          })}
        {(currentClub?.active || currentClub?.communityPartnershipDetails?.programType === programTypes.COMMUNITY_PARTNERSHIP) && (() => {
          let isRenewal = isRenewalAvailable(currentClub);

          return (
            <React.Fragment>
              {isYears && <Typography className={classes.yearContainer}>LAST VIEWED</Typography>}
              <ProgramCard
                key={currentClub.id}
                program={currentClub}
                icon={renderIcon(currentClub, programTypesById)}
                onClick={() => onClick(currentClub)}
                enableRenewal={isRenewal}
                isRenewed={!!currentClub.renewalFormSubmitted}
                onRenewClick={() => onRenewClick(currentClub)}
                programTypesById={programTypesById}
              />
            </React.Fragment>
          )
        })()
        }
        {isYears && programItems}
        {!isYears && programs &&
          programs.filter(el => el.active).map((p) => {
            let isRenewal = isRenewalAvailable(p);

            return (
              <ProgramCard
                key={p.id}
                program={p}
                icon={renderIcon(p, programTypesById)}
                onClick={() => onClick(p)}
                isRenewed={!!p.renewalFormSubmitted}
                enableRenewal={isRenewal}
                onRenewClick={() => onRenewClick(p)}
                programTypesById={programTypesById}
              />
            );
          })}
        {contentful && programToRenew && (
          <RenewalModal
            isOpen={renewal}
            labels={contentful}
            currentRole={programToRenew}
            onClose={() => {
              setRenewal(false);
              setProgramToRenew(null);
            }}
          />
        )}
      </Grid>
      <Grid container>
        {completedItems}
        {completedCpItems}
      </Grid>
    </div>
  );
};

export default ProgramsList;
