import Cookies from 'js-cookie';

const COOKIE_TOKEN = 'token';
const COOKIE_TOKEN_REFRESH = 'refreshToken';
const COOKIE_TOKEN_REFRESH_HASH = 'refreshTokenHash';

window.addEventListener('storage', (event) => {
  if (isCookiesEnabled() && event.key === getCookieTokenRefreshHash()) {
    window.location.reload();
  } else if (!isCookiesEnabled() && event.key === getCookieTokenRefresh()){
    window.location.reload();
  }
})

const getCookieToken = () => {
  return getCookieEnvName(COOKIE_TOKEN);
}

const getCookieTokenRefreshHash = () => {
  return getCookieEnvName(COOKIE_TOKEN_REFRESH_HASH);
}

const getCookieTokenRefresh = () => {
  return getCookieEnvName(COOKIE_TOKEN_REFRESH);
}

export const getCookieDomain = () => {
  return window._env_.REACT_APP_COOKIE_DOMAIN ?? '.localhost';
}

export const getCookieEnvName = (name) => {
  const suffix =  window._env_.REACT_APP_COOKIE_TOKEN_SUFFIX ?? '';
  return `${name}_${suffix}`;
}

export const isCookiesEnabled = () => {
  return navigator.cookieEnabled;
}

export const getToken = () => {
  return isCookiesEnabled() ? Cookies.get(getCookieToken()) :  localStorage.getItem(getCookieToken());
};

export const getRefreshToken = () => {
  return isCookiesEnabled() ? Cookies.get(getCookieTokenRefresh()) :  localStorage.getItem(getCookieTokenRefresh());
};

export const setToken = (token) => {
  if (isCookiesEnabled()) {
    const cookieDomain = getCookieDomain();
    Cookies.set(getCookieToken(), token, { domain: cookieDomain });
  } else {
    localStorage.setItem(getCookieToken(), token);
  }
};

export const setRefreshToken = (refreshToken) => {
  if (isCookiesEnabled()) {
    const cookieDomain = getCookieDomain();
    Cookies.set(getCookieTokenRefresh(), refreshToken, { domain: cookieDomain });
  } else {
    localStorage.setItem(getCookieTokenRefresh(), refreshToken);
  }

  //We need it to detect in addEventListener('storage') changes in login status
  localStorage.setItem(getCookieTokenRefreshHash(), Date.now());
};


export const clear = () => {
  localStorage.removeItem(getCookieToken());
  localStorage.removeItem(getCookieTokenRefresh());
  localStorage.removeItem(getCookieTokenRefreshHash());

  localStorage.removeItem('fingerprint');
  localStorage.removeItem('lastActivity');
  localStorage.removeItem('welcomeModals');
  localStorage.removeItem('cookieAgreement');
  localStorage.removeItem('signupCountryId');
  localStorage.removeItem('gwcPartnershipAnnouncements');
  localStorage.removeItem('facilitatorConsent');

  const cookieDomain = getCookieDomain();
  Cookies.remove(getCookieToken(), { domain: cookieDomain });
  Cookies.remove(getCookieTokenRefresh(), { domain: cookieDomain });
};

export const clearAll = () => {
  clear();
  localStorage.clear();
};

export const getAuthHeader = () => {
  const token = getToken();
  if (!token) return {};
  
  return {
    'Authorization': `Bearer ${token}`
  }
};
