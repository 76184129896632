import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import Title from "./Title";

import MetaIcons from "./MetaIcons";
import { createMarkup } from "../../utils/generic";

const titleStyles = makeStyles((theme) => ({
    goalsBox: {
      minWidth: "100%",
      minHeight: 100,
      margin: "1px 0 0 0",
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    goalsBoxContainer: {
      display: "flex",
      maxWidth: 1160,
      padding: "20px 0",
      [theme.breakpoints.down("xs")]: {
        padding: "10px 0",
      },
    },
    goalItem: {
      fontSize: 24,
      color: prop => prop.themeFont,
      [theme.breakpoints.down("xs")]: {
        fontSize: 20,
      },
    },
    goalDescription: {
      fontSize: 16,
      color: prop => prop.themeFont,
    },
    label: {
      height: 14,
      fontSize: 10,
      fontFamily: "'Roboto Mono',monospace",
      fontWeight: 500,
      cursor: "text",
      "&:hover": {
        cursor: "text",
      },
      marginLeft: 15,
      marginRight: 10,
      marginTop: 12,
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
        marginTop: 0,
      },
      lineHeight: "0.5",
    },
  }));

const CurriculumHeader = ({
    user,
    contentful,
    currentNode,
    currentRole,
    nodeTheme,
    chain,
    labelBackground,
    labelFontColor,
    showGoals,
    setShowGoals,
    isFacilitatorOnlyAvailable,
    studentView,
    parentNode,
    curriculum,
    presentationMode,
    activityStep,
    activity,
    createUsageLog,
    setCurrentActivityInfo,
    setStartContentSectionTime,
    setIsPresentationMode,
    disabledNext,
    onNextClick,
    next,
    setActivityStep,
    push,
    location,
    programRoleLabel,
    certificateRibbonIcon,
    studentsSections,
    isOpenQuestionAnswerUpdated,
    setOpenQuestionAnswerUpdated,
    setDisabledNext,
    answeredQuestionsState,
    activityUnfinishedSections,
    onSectionClick,
    isFacilitator,
}) => {
    const [goals, setGoals] = React.useState(null);
    const [materials, setMaterials] = React.useState(null);
    const [infoSet, setInfoSet] = React.useState(null);

    React.useEffect(() => {
        if (!currentNode) return;

        setGoals(null);
        setMaterials(null);
        setInfoSet(null);

        const isActivityType =
            currentNode.type === "activity" || currentNode.nodeType === "activity";

        if (currentNode.goals) {
            setGoals(currentNode.goals);
        }
        if (isActivityType && curriculum.currentActivity) {
            setGoals(curriculum.currentActivity.goals);
        }
        if (currentNode.materials) {
            setMaterials(currentNode.materials);
        }
        if (isActivityType && curriculum.currentActivity) {
            setMaterials(curriculum.currentActivity.materials);
        }
        if (currentNode.introduction) {
            setInfoSet(currentNode.introduction);
        }
        if (isActivityType && curriculum.currentActivity) {
            setInfoSet(curriculum.currentActivity.introduction);
        }
    }, [curriculum.currentActivity, currentNode]);

    const titleClasses = titleStyles({ themeFont: nodeTheme.f });
    const renderTitle = !!chain ? !!chain.length : false;

    return (
        <>
            {renderTitle && !!currentNode &&
                <Title
                    nodeTheme={nodeTheme}
                    chain={chain}
                    currentNode={currentNode}
                    titleClasses={titleClasses}
                    labelBackground={labelBackground}
                    labelFontColor={labelFontColor}
                    goals={goals}
                    materials={materials}
                    infoSet={infoSet}
                    showGoals={showGoals}
                    setShowGoals={setShowGoals}
                    isFacilitatorOnlyAvailable={isFacilitatorOnlyAvailable}
                    programRoleLabel={programRoleLabel}
                    certificateRibbonIcon={certificateRibbonIcon}
                    isFacilitator={isFacilitator}
                    studentView={studentView}
                />}

            {currentNode && (
                <Box
                    hidden={!showGoals}
                    className={titleClasses.goalsBox}
                    style={{ backgroundColor: nodeTheme.b }}
                >
                    <Container className={titleClasses.goalsBoxContainer}>
                        {infoSet && (
                            <Container>
                                <Typography className={titleClasses.goalItem}>Info</Typography>
                                <div
                                    className={titleClasses.goalDescription}
                                    dangerouslySetInnerHTML={createMarkup(infoSet)}
                                ></div>
                            </Container>
                        )}
                        {goals && (
                            <Container>
                                <Typography className={titleClasses.goalItem}>Goals</Typography>
                                <div
                                    className={titleClasses.goalDescription}
                                    dangerouslySetInnerHTML={createMarkup(goals)}
                                ></div>
                            </Container>
                        )}
                        {materials && (
                            <Container>
                                <Typography className={titleClasses.goalItem}>
                                    Materials
                                </Typography>
                                <div
                                    className={titleClasses.goalDescription}
                                    dangerouslySetInnerHTML={createMarkup(materials)}
                                ></div>
                            </Container>
                        )}
                    </Container>
                </Box>
            )}
            {currentRole && currentRole.program && !isFacilitatorOnlyAvailable && <MetaIcons
                user={user}
                parentNode={parentNode}
                currentNode={currentNode}
                contentful={contentful}
                currentRole={currentRole}
                curriculum={curriculum}
                presentationMode={presentationMode}
                location={location}
                studentsSections={studentsSections}
                activityStep={activityStep}
                activity={activity}
                createUsageLog={createUsageLog}
                setCurrentActivityInfo={setCurrentActivityInfo}
                setStartContentSectionTime={setStartContentSectionTime}
                setShowGoals={setShowGoals}
                setIsPresentationMode={setIsPresentationMode}
                disabledNext={disabledNext}
                onNextClick={onNextClick}
                next={next}
                setActivityStep={setActivityStep}
                push={push}
                studentView={studentView}
                isOpenQuestionAnswerUpdated={isOpenQuestionAnswerUpdated}
                setOpenQuestionAnswerUpdated={setOpenQuestionAnswerUpdated}
                setDisabledNext={setDisabledNext}
                answeredQuestionsState={answeredQuestionsState}
                activityUnfinishedSections={activityUnfinishedSections}
                onSectionClick={onSectionClick}
            />}
        </>
    );
};


export default CurriculumHeader;