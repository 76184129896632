import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as moment from "moment";
import * as momentTimezone from "moment-timezone";
import * as Yup from "yup";
import { isMobile } from "react-device-detect";
import clsx from "clsx";

import Collapse from "@material-ui/core/Collapse";
import ChipItem from "./chipItem";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import DateInput from "../../../components/date-input/DateInput";
import TextInput from "../../../components/TextInput";
import Selector from "../../../components/selector/Selector";

import MultipleGroupedSelector from "./multipleGroupedSelector";
import MultipleSelector from "../../../components/multipleSelect/customSelect";
import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";
import LocationOnOutlined from "@material-ui/icons/LocationOnOutlined";
import SubjectIcon from "@material-ui/icons/Subject";
import Checkbox from "../../CheckBox";
import { getUserName } from "../../../utils/generic";
import TextFieldGroup from "../members/TextFieldGroup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { isValidEmail } from "../../../utils/generic";
import { handleKeyPress } from '../../../utils/accessibility';
import { isEqual } from 'lodash';
import Roles from "../../../utils/permissions";
import { DOMESTIC_ID } from "../../../utils/countriesDict";
import FocusError from "../../../containers/LoginSignUp/Register/FocusError";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: 20,
  },
  marginTop30: {
    marginTop: 30,
  },
  programSelectBox: {
    marginRight: 10,
    [theme.breakpoints.down("md")]: {
      marginBottom: 10,
    },
    "& .MuiSelect-selectMenu": {
      color: colors.darkThemeBlueGray,
    },
  },
  subContent: {
    marginTop: 30,
    marginBottom: 10,
    textTransform: "uppercase",
    color: colors.lightGrey,
  },
  subContentLabel: {
    color: colors.darkThemeBlueGray,
    fontWeight: 500,
  },
  sectionItem: {
    marginRight: 10,
    [theme.breakpoints.down("md")]: {
      marginBottom: 10,
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 22,
    fontWeight: "bold",
    margin: "25px auto 15px",
    maxWidth: 330,
    textAlign: "center",
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
    fontSize: 16,
  },
  optionGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    display: "flex",
    boxShadow: "none",
  },
  button: {
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500,
    },
  },
  boxSection: {
    display: "inline-flex",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "block",
      justifyContent: "space-between",
    },
  },
  repeatedBoxSection: {
    marginTop: 5,
    display: "block",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
  },
  inviteesSection: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  boxSubSection: {
    display: "inline-flex",
    width: "48%",
    display: "block",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
      width: "100%",
    },
  },
  attendeesBoxSection: {
    display: "block",
    justifyContent: "space-between",
  },
  itemSection: {
    width: "100%",
  },
  submitButton: {
    borderRadius: 5,
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.teal500,
    },
  },
  boxWrapper: {
    display: "flex",
    alignItems: "center",
  },
  boxIcon: {
    marginRight: 10,
  },
  locationLink: {
    "& > label:not(.Mui-focused)": {
      [theme.breakpoints.down("sm")]: {
        right: 24,
        top: -8
      },
    }
  },
  optionTag: {
    background: colors.greyLight2,
  },
  partnerTag: {
    background: "#FFF3E8",
  },
  repeatLink: {
    color: colors.teal500,
    cursor: "pointer",
    fontSize: 14,
    lineHeight: 1.5,
    textTransform: "uppercase",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
    '&:focus': {
      outline: 'thin solid black',
      outlineOffset: 2,
    },
  },
  checkboxBox: {
    marginTop: 10,
    "& .MuiFormControlLabel-label": {
      marginLeft: 8
    }
  },
  checkBoxLabel: {
    fontSize: 17,
  },
  titleBox: {
    display: "flex",
    marginTop: 30,
    marginBottom: 10,
  },
  expandBtn: {
    cursor: "pointer",
    color: colors.darkThemeGreen,
  },
  focusInput: {
    resize: "none",
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: () => `2px solid ${colors.lightThemeGreen}`,
      },
    },
    "& .MuiOutlinedInput-root": {
      fontSize: 14,
    },
    "&:focus": {
      outlineColor: colors.lightThemeGreen,
    }
  },
  repeatTitleBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  dividerItem: {
    marginTop: 10,
  },
  expandBox: {
    display: "flex",
    cursor: "pointer",
    '&:focus': {
      outline: 'thin solid black',
      outlineOffset: 2,
    },
  },
  externalInviteesLabel: {
    marginTop: 30,
    marginBottom: 10,
    textTransform: "uppercase",
    color: colors.teal500,
    cursor: "pointer",
    fontSize: 14,
    maxWidth: "max-content",
    '&:focus': {
      outline: 'thin solid black',
      outlineOffset: 2,
    },
  },
  externalBox: {
    marginBottom: 20,
  }
}));

const repeatItems = [
  { value: "NONE", label: "None" },
  { value: "DAILY", label: "Daily" },
  { value: "WEEKLY", label: "Weekly" },
  { value: "MONTHLY", label: "Monthly" },
];

const getPrograms = (event, programs) => {
  let programsList = [];
  if (event && event.programs) {
    programsList = [
      ...event.programs
        .filter((e) => !!e.program)
        .map((e) => e.program)
        .map((el) => {
          return {
            id: el.id,
            label: el.name,
          };
        }),
    ];
  }

  return (programsList = [
    ...programsList,
    ...programs
      .map((el) => {
        if (programsList.find((e) => e.id === el.id)) {
          return;
        }
        return {
          id: el.id,
          label: el.name,
        };
      })
      .filter((e) => e),
  ]);
};

const validateEmail = value => {
  return !value || !value.trim().length || isValidEmail(value) ? false : true;
};

const checkDuplicates = (array, item) => {
  const result = array.filter((e) => e.id === item.userId);
  return !result.length;
};

const calcWithoutTimezone = (value) => {
  const date = new Date(value);
  var datestring =
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2);
  return datestring;
};

const calcDateTime = (date, time, timezone) => {
  const parsedDate = new Date(Date.parse(date));
  var datestring =
    parsedDate.getFullYear() +
    "-" +
    ("0" + (parsedDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + parsedDate.getDate()).slice(-2) +
    " " +
    time;

  const momentDate = momentTimezone.tz(datestring, timezone);
  const result = new Date(momentDate).toISOString();

  return result;
};

const getConvertedTime = (date, timezone = null) => {
  const convertedDate = timezone !== null ? momentTimezone(date).clone().tz(timezone) : date;
  // const localStartDate = convertedStartDate.format("YYYY-MM-DD");
  const localTime = convertedDate.format("HH:mm");
  return localTime;
};

const getCurrTime = (isEndTime) => {
  const currentDateTime = moment();
  const minuteReminder = 30 - (currentDateTime.minute() % 30);
  currentDateTime.add(minuteReminder, "minutes");

  if (isEndTime) {
    currentDateTime.add(30, "minutes");
  }

  return currentDateTime.format("HH:mm");
};

const getStartDate = (event, timezone) => {
  if (!event) {
    return new Date();
  }

  if (event.originStartDate) {
    return new Date(
      `${momentTimezone(event.originStartDate)
        .clone()
        .tz(timezone)
        .format("YYYY-MM-DD")}T00:00:00`
    );
  }
  return new Date(`${event.localStartDate}T00:00:00`);
};

export const EventSchedulerContent = ({
  event,
  programs,
  onCloseModal,
  updateEvent,
  createEvent,
  timezone,
  loadProgramMembers,
  programMembers,
  isLoadingProgramMembers,
  setCloseCreateEventModal,
  isClosedCreateEventModal,
  setEscapeConfirmDialog,
  countryId,
  isInProgress
}) => {
  const classes = useStyles();
  const repeatUntill =
    event && !!event.repeatingUntil
      ? new Date(
        `${momentTimezone(event.repeatingUntil)
          .clone()
          .tz(timezone)
          .format("YYYY-MM-DD")}T00:00:00`
      )
      : new Date(
        `${momentTimezone()
          .clone()
          .tz(timezone)
          .format("YYYY-MM-DD")}T00:00:00`
      );
  const [openCompleted, setOpenCompleted] = React.useState(
    event && !!event.repeatingFreq
      ? !event.repeatingFreq &&
      !!event.repeatingFreq.trim().length &&
      event.repeatingFreq !== "NONE"
      : false
  );
  const [openExternalAttendees, setOpenExternalAttendees] = React.useState(
    event && !!event?.externalAttendees?.length
  );
  const [selectedProgramIds, setSelectedProgramIds] = useState(
    event && event.programs?.length ? event.programs.map((e) => e.programId) : []
  );

  let startTime;
  let endTime;

  if (event && event.convertedStartDate) {
    startTime = getConvertedTime(event.convertedStartDate);
  } else if (event && event.localStartTime) {
    startTime = getConvertedTime(event.startDate, timezone);
  } else {
    startTime = getCurrTime();
  }

  if (event && event.convertedEndDate) {
    endTime = getConvertedTime(event.convertedEndDate);
  } else if (event && event.endDate) {
    endTime = getConvertedTime(event.endDate, timezone);
  } else {
    endTime = getCurrTime(true);
  }

  const [expandInvitees, setExpandInvitees] = useState(false);
  const [expandDetails, setExpandDetails] = useState(false);

  const [showExternalAttendees, setShowExternalAttendees] = useState(!event ||
    (!event.isPublic && (!event.repeatingFreq || event.repeatingFreq === 'NONE')));

  const onProgramChange = (newValues, oldValues, programsSectionItems) => {
    const newProgramsSelected = newValues.filter(e => !oldValues.includes(e));
    const programWithoutLoadedMembers = programsSectionItems
      .filter(e => newProgramsSelected.includes(e.id) && !programMembers[e.id]);

    if (!!programWithoutLoadedMembers.length) {
      loadProgramMembers(programWithoutLoadedMembers.map(e => e.id));
    }
  };

  let repeatableEventChange = (value, setFieldValue) => {
    if (value !== 'NONE') {
      setFieldValue("externalInvitees", []);
      setFieldValue("currentExternalEmails", "");
      setShowExternalAttendees(false)
    } else {
      setShowExternalAttendees(true);
    }
  }

  const members =
    event && event.attendees ? event.attendees.map((e) => e.userId) : [];

  const externalMembersEmails = event && event.externalAttendees ? event.externalAttendees.map(e => e.email) : [];

  const programsFacilitatorOrViewerIds = programs.filter((e) => [Roles.Facilitator, Roles.Viewer].includes(e.roleId)).map((e) => e.id);

  // convert programs
  let programsSectionItems = getPrograms(event, programs);

  const programsSectionFacilitatorItems = programsSectionItems.filter((e) => {
    return programsFacilitatorOrViewerIds.includes(e.id);
  });

  // convert members
  let programMembersList = [];
  programsSectionItems
    .filter((e) => selectedProgramIds.includes(e.id))
    .map((e) => {
      const membersList = programMembers[e.id];
      if (!!membersList) {
        programMembersList = [...programMembersList, ...membersList];
      }
    });

  const teachers = programMembersList
    .filter((e) => e.roleId == 1)
    .map((el) => {
      return {
        id: el.userId,
        label: `${el.firstName} ${el.lastName}`,
      };
    })
    .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

  const students = programMembersList
    .filter((e) => e.roleId == 2 && checkDuplicates(teachers, e))
    .map((el) => {
      return {
        id: el.userId,
        label: getUserName(el),
      };
    })
    .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

  const viewers = programMembersList
    .filter(
      (e) => e.roleId == 3 && checkDuplicates([...teachers, ...students], e)
    )
    .map((el) => {
      return {
        id: el.userId,
        label: `${el.firstName} ${el.lastName}`,
      };
    })
    .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

  let items = {
    teachers,
    students,
    viewers,
  };

  const initialDates = {
    startTime,
    endTime,
    date: getStartDate(event, timezone),
  };
  return (
    <Formik
      // enableReinitialize={true}
      initialValues={{
        name: event ? event.name : "",
        url: event && event.url ? event.url : "",
        date: getStartDate(event, timezone),
        description: event && event.description ? event.description : "",
        startTime: startTime,
        endTime: endTime,
        programIds: selectedProgramIds,
        attendeeIds: members,
        isOptional: event ? event.isOptional : false,
        isPartner: event ? event.isPartner : false,
        repeatingFreq:
          event && event.repeatingFreq ? event.repeatingFreq : "NONE",
        repeatingUntil: repeatUntill,
        autoInviteMembers: event ? event.autoInviteMembers : false,
        currentExternalEmails: event ? externalMembersEmails.join(' ') : "",
        externalInvitees: event ? externalMembersEmails : [],
      }}
      validationSchema={Yup.object({
        description: Yup.string().trim().typeError("Required").nullable(),
        date: Yup.date().required("Required").nullable(),
        name: Yup.string().trim().required("Required").typeError("Required"),
        programIds: Yup.array().required("Required"),
        attendeeIds: Yup.array().required("Required"),
        repeatingUntil: Yup.date().required("Required").nullable(),
      })}
      validate={(values) => {
        let errors = {};

        const { 
          startTime,
          endTime,
          repeatingFreq,
          date,
          repeatingUntil,
          currentExternalEmails,
          name,
          programIds,
          attendeeIds,
          url,
          description,
          autoInviteMembers,
        } = values;
        if (startTime >= endTime) {
          errors.endTime = "End time should be higher then start time";
        }

        setCloseCreateEventModal(
          (!!name || (!!name && name !== event?.name)) ||
          (programIds.length || isEqual(programIds, event?.programIds)) ||
          (attendeeIds.length || isEqual(attendeeIds, event?.attendeeIds)) ||
          (!!url || (!!url && url !== event?.url)) ||
          (!!description || (!!description && description !== event?.description )) ||
          (!!autoInviteMembers || (!!autoInviteMembers !== !!event?.autoInviteMembers)) || 
          startTime !== initialDates.startTime ||
          endTime !== initialDates.endTime ||
          moment(date).format('YYYY-MM-DD') !== moment(initialDates.date).format('YYYY-MM-DD')
        );
        if (repeatingFreq !== "NONE" &&
          new Date(new Date(date).setHours(0, 0, 0, 0)) >=
          new Date(new Date(repeatingUntil).setHours(0, 0, 0, 0))
        ) {
          errors.repeatingUntil = "End date should be higher then start date";
        }

        const parsedValues = currentExternalEmails
          .replaceAll("\n", " ")
          .split(" ")
          .map((e) => e.trim());
        const invalidPart = parsedValues.find(
          (e) => !!e && !validateEmail(e)
        );

        if (!!invalidPart) {
          errors.currentExternalEmails = "Invalid email in list";
        }

        return errors;
      }}
      onSubmit={(values) => {
        const newValues = {
          ...values,
          timezone,
          startDate: calcDateTime(values.date, values.startTime, timezone),
          endDate: calcDateTime(values.date, values.endTime, timezone),
        };

        newValues.repeatingUntil =
          !values.repeatingFreq || values.repeatingFreq === "NONE"
            ? null
            : `${calcWithoutTimezone(values.repeatingUntil)}T${newValues.endDate.split("T")[1]
            }`;

        if (event && event.id) {
          setCloseCreateEventModal(false);
          updateEvent({ ...newValues, id: event.id });
        } else {
          setCloseCreateEventModal(false);
          createEvent({ event: newValues });
        }
      }}
    >
      {({ values }) => (<Form>
        <Field
          name="name"
          type="text"
          component={TextInput}
          label="Title"
          multiline
          rows={1}
          rowsMax={1}
          className={classes.focusInput}
          disabled={isInProgress}
          required
        />
        <Box className={classes.boxSection} style={{ marginTop: 30 }}>
          <Field
            name="date"
            component={DateInput}
            label="Date"
            className={clsx(classes.sectionItem, classes.focusInput)}
            domesticId={DOMESTIC_ID}
            countryId={countryId}
            standardDateFormat={true}
            disabled={isInProgress}
            required
          />
          <Field
            type="time"
            name="startTime"
            component={TextInput}
            label="Start"
            stepValue={!isMobile ? 1800 : null}
            className={clsx(classes.sectionItem, classes.focusInput)}
            disabled={isInProgress}
            required
          />
          <Field
            type="time"
            name="endTime"
            stepValue={!isMobile ? 1800 : null}
            component={TextInput}
            label="End"
            className={classes.focusInput}
            disabled={isInProgress}
            required
          />
        </Box>
        <Box className={classes.repeatedBoxSection}>
          <Box className={classes.repeatTitleBox}>
            <Link
              className={classes.repeatLink}
              tabIndex={0}
              onClick={() => setOpenCompleted(!openCompleted)}
              onKeyDown={handleKeyPress(() => setOpenCompleted(!openCompleted))}
              sel="repeate-event"
            >
              Does this event repeat?
            </Link>
            <Typography>{timezone}</Typography>
          </Box>
          <Collapse
            style={{ paddingTop: 20 }}
            in={openCompleted}
            timeout="auto"
            unmountOnExit
          >
            <Box className={classes.boxSection}>
              <Field
                className={clsx(classes.programSelectBox, classes.focusInput)}
                options={repeatItems}
                component={Selector}
                name="repeatingFreq"
                label="Repeat"
                type="text"
                onValueChange={repeatableEventChange}
                disabled={isInProgress}
              />
              <Field
                name="repeatingUntil"
                component={DateInput}
                disabled={isInProgress || values.repeatingFreq === 'NONE'}
                label="End Date"
                className={clsx(classes.sectionItem, classes.focusInput)}
                domesticId={DOMESTIC_ID}
                countryId={countryId}
                standardDateFormat={true}
              />
            </Box>
          </Collapse>
        </Box>
        <Box className={classes.titleBox}>
          <Box
            className={classes.expandBox}
            tabIndex={0}
            onClick={() => setExpandInvitees(!expandInvitees)}
            onKeyDown={handleKeyPress(() => setExpandInvitees(!expandInvitees))}
          >
            <Typography className={classes.subContentLabel}>Invitees</Typography>
            {expandInvitees ? <ExpandMoreIcon className={classes.expandBtn} />
              : <KeyboardArrowRightIcon className={classes.expandBtn} />}
          </Box>
        </Box>
        {expandInvitees && <Box>

          <Box className={classes.inviteesSection}>
            <Box className={classes.boxSubSection}>
              <Typography>Programs*</Typography>
              <Field
                name={`programIds`}
                component={MultipleSelector}
                label="Programs"
                errorIsHtml
                required
                className={clsx(classes.programSelectBox, classes.focusInput)}
                options={programsSectionFacilitatorItems}
                onValueChange={(values, func, options) => {
                  const currentUrl = options["url"];

                  if (
                    (values.length > 1 || !values.length) &&
                    (!event || !event.url)
                  ) {
                    func("url", "");
                  }

                  if (values.length === 1) {
                    const p = programs.find((e) => values.includes(e.id));
                    if (p && p.virtualConferenceLink) {
                      func("url", p.virtualConferenceLink);
                    } else if (!event || event.url !== currentUrl) {
                      func("url", "");
                    }
                  }

                  onProgramChange(values, options.programIds, programsSectionItems);
                  setSelectedProgramIds(values);
                }}
                disabled={isInProgress}
              />
            </Box>
            <Box className={classes.boxSubSection}>
              <Typography>Attendees</Typography>
              <Field
                name={`attendeeIds`}
                component={MultipleGroupedSelector}
                label="People"
                options={items}
                isLoading={isLoadingProgramMembers}
                className={classes.focusInput}
                disabled={!selectedProgramIds.length || isInProgress}
                required
              />
            </Box>
          </Box>
          {showExternalAttendees && <Box className={classes.externalBox}>
            <Typography
              className={classes.externalInviteesLabel}
              tabIndex={0}
              onClick={() => setOpenExternalAttendees(!openExternalAttendees)}
              onKeyDown={handleKeyPress(() => setOpenExternalAttendees(!openExternalAttendees))}
            >
              Invite External Guests
            </Typography>
            {openExternalAttendees && <Box className={classes.attendeesBoxSection}>
              <Field
                name="currentExternalEmails"
                originalValue="externalInvitees"
                component={TextFieldGroup}
                rowsMin={10}
                rowsMax={40}
                validateEmailList={validateEmail}
                label={"Email"}
                countLabel={`Send invitations to ({count}) external guests`}
                className={classes.focusInput}
                disabled={isInProgress}
                required
              />
            </Box>}
          </Box>}
        </Box>}
        <Divider className={classes.dividerItem} />
        <Box className={classes.titleBox}>
          <Box
            className={classes.expandBox}
            tabIndex={0}
            onClick={() => setExpandDetails(!expandDetails)}
            onKeyDown={handleKeyPress(() => setExpandDetails(!expandDetails))}
          >
            <Typography className={classes.subContentLabel}>Details</Typography>
            {expandDetails ? <ExpandMoreIcon className={classes.expandBtn} />
              : <KeyboardArrowRightIcon className={classes.expandBtn} />}
          </Box>
        </Box>
        {expandDetails && <Box>
          <Box className={classes.boxWrapper}>
            <LocationOnOutlined className={classes.boxIcon} />
            <Field
              name="url"
              type="text"
              component={TextInput}
              classes={{ root: classes.locationLink }}
              label="Add location or video conferencing link"
              className={classes.focusInput}
              disabled={isInProgress}
            />
          </Box>
          <Box className={classes.boxWrapper}>
            <SubjectIcon className={classes.boxIcon} />
            <Field
              name="description"
              type="text"
              component={TextInput}
              className={clsx(classes.marginTop30, classes.focusInput)}
              label="Description"
              multiline
              rows={2}
              rowsMax={4}
              disabled={isInProgress}
            />
          </Box>
          <Field
            className={classes.checkboxBox}
            labelStyle={classes.checkBoxLabel}
            name="autoInviteMembers"
            component={Checkbox}
            label={"Automatically invite new Members"}
            disabled={isInProgress}
          />
          {event && (event.isOptional || event.isPartner) && (
            <Box className={classes.optionGroup}>
              <Field
                name="isOptional"
                size="medium"
                variant="outlined"
                component={ChipItem}
                className={classes.optionTag}
                label="Optional"
                disabled={isInProgress}
              />
              <Field
                name="isPartner"
                size="medium"
                variant="outlined"
                component={ChipItem}
                className={classes.partnerTag}
                label="Partner"
                disabled={isInProgress}
              />
            </Box>
          )}

        </Box>}
        <Divider className={classes.dividerItem} />
        <div className={classes.buttonGroup}>
          <CustomButton
            mode="textButton"
            onClick={() => {
              isClosedCreateEventModal ? setEscapeConfirmDialog(true) : onCloseModal()
            }}
            disabled={isInProgress}
            label={"Cancel"}
          />
          <CustomButton mode="primary" type="submit" disabled={isInProgress} label={"Save"} onClick={() => {
            setExpandInvitees(true);
          }} />
        </div>
        <FocusError/>
      </Form>)}
    </Formik>
  );
};
