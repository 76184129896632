import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";

import * as moment from "moment";
import {
  invalidCredentials,
  receiveAuthData,
  loadCurrentUser as loadCurrentUserAction,
  currentUserLoaded,
  receivedHome,
  receivedChild,
  logoutSuccess,
  enrolledChild,
  setChildReceived,
  receivedParentConsent
} from "../actions/auth";
import { 
  resetAnswerProgress,
} from "../actions/openText";
import { decrementInitialLoaders, setDefaultState } from "../actions/global";
import { login, loginChild, logout as logoutApi, registerChild } from "../api/index";
import { saveCurrentRole } from "../api/graphql/mutation/userRoles.mutation";
import * as usersApi from "../api/users";
import * as globalApi from '../api/global';
import * as enrollmentApi from '../api/bl-query/enrollment.query';

import { createUsageLog, createTrackingLog } from "../actions/tracking";
import * as programsApi from "../api/programs";
import { setToken, setRefreshToken, clear, getRefreshToken } from "../tokenStorage";
import {
  STUDENT_JOINED_CLUB,
  ENROLL_PROGRAM_STARTED,
  SWITCHED_CHILD_VIEW,
  SWITCHED_PARENT_VIEW,
  LOGIN_USER,
  LOGIN_USER_GOOGLE,
} from "../constants/trackingEvents";
import { showErrorMessage } from '../actions/notification';
import { downloadFile } from "../utils/downloadFile";
import {clearStateForPasswordChangedResult} from "../actions/userProfile";

const adultAge = 18;

export function* authorize({ payload }) {
  try {
    payload.login = payload.login.trim();
    let {
      data: { token, refreshToken, user },
    } = yield call(login, payload);

    setToken(token);
    setRefreshToken(refreshToken);
    if (payload.googleLogin) {
      yield put(createTrackingLog({ event: LOGIN_USER_GOOGLE, userNextId: user.id }));
    } else {
      yield put(createTrackingLog({ event: LOGIN_USER, userNextId: user.id }));
    }

    yield put(receiveAuthData({ token, refreshToken, user }));

    yield put(loadCurrentUserAction());
  } catch (e) {
    yield put(invalidCredentials(e.response.data.errorType));
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* authorizeParent({ payload }) {
  try {
    payload.login = payload.login.trim();
    let {
      data: { token, refreshToken, user },
    } = yield call(login, payload);

    setToken(token);
    setRefreshToken(refreshToken);

    yield put(decrementInitialLoaders());
    yield put(createUsageLog({ action: SWITCHED_PARENT_VIEW }));
    yield put(receiveAuthData({ token, refreshToken, user }));

    yield put(loadCurrentUserAction());

    yield put(push("/home"));
  } catch (e) {
    yield put(invalidCredentials(e.response.data.errorType));
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* authorizeChild({ payload }) {
  try {
    let {
      data: { token, refreshToken, user },
    } = yield call(loginChild, payload);

    yield put(createUsageLog({ action: SWITCHED_CHILD_VIEW }));
    yield put(receiveAuthData({ token, refreshToken, user }));
    yield put(resetAnswerProgress());
    yield put(loadCurrentUserAction());
  } catch (e) {
    yield put(invalidCredentials());
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* logout() {
  const isProjectGalleryPage = window.location.href.includes("project-gallery");
  try {
    let refreshToken = getRefreshToken();
    try {
      yield call(logoutApi, { refreshToken });
    } catch (err) {
      console.log(err);
    }
    clear();
    if (isProjectGalleryPage) {
      window.location.reload();
    } else {
      yield put(push("/"));
    };
    yield put(logoutSuccess());
    yield put(setDefaultState());
    yield put(resetAnswerProgress());
  } catch (e) {
    clear();
    yield put(setDefaultState());
    yield put(logoutSuccess());
    if (isProjectGalleryPage) {
      window.location.reload();
    } else {
      yield put(push("/"));
    };
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}

export function* loadCurrentUser({ payload }) {
  try {
    const now = moment();
    let { data } = yield call(usersApi.getMyInfo);

    const UK_ID = '224';
    if (payload && data) {
      const { clubCode, birthDate } = payload;
      const countryId = payload?.country;
      let dobDate = moment.parseZone(birthDate).format();
      const age = now.diff(dobDate, "year");
      if ((countryId !== UK_ID || clubCode !== '') && age < adultAge) {
        data.redirect = clubCode
          ? `/join-program/clubCode/${clubCode}`
          : `/join-program`;
      }
    }

    yield put(currentUserLoaded({...data, ...{isParent: payload?.isParent}}));
    yield put(decrementInitialLoaders());
    yield put(clearStateForPasswordChangedResult());
    // log out in case user token is not valid
    if (!data) {
      clear();
      yield put(push("/home"));
    }
  } catch {
    yield put(decrementInitialLoaders());
  }
}

export function* changeRole({ payload }) {
  try {
    yield call(saveCurrentRole, payload);
  } catch {
    yield put(decrementInitialLoaders);
  }
}

export function* setHome({ payload }) {
  try {
    yield put(receivedHome(payload));
  } catch {
    yield put(decrementInitialLoaders);
  }
}

export function* updateChild({ payload }) {
  try {
    const { data: { userData, error } } = yield call(usersApi.updateChildInfo, payload);
    if (!!error) {
      yield put(showErrorMessage('There was an error while updating user\'s information. Please try again later.'));
    }
    yield put(receivedChild({ child: userData }));
  } catch {
    yield put(decrementInitialLoaders);
  }
}

export function* createChild({ payload }) {
  try {
    const { childInfo, childPassword, program, enrollmentData } = payload;   
    const { data: createChildNext } = yield call(registerChild, { childInfo, childPassword } );

    yield put(receivedChild({ child: createChildNext }));
    const { data: programByCode } = yield call(programsApi.getProgramByCode, {
      programCode: program.code
    });
    yield put(setChildReceived({...createChildNext, program: programByCode.name}));

    let { data: {
      hqConfigControl,
    } } = yield call(globalApi.getGlobalInfo, payload);

    if (hqConfigControl.defaultProgram === programByCode.code) {
      throw( new Error('\n Club code belongs to default program'));
    }

    yield put(
      createUsageLog({
        action: ENROLL_PROGRAM_STARTED,
        data: JSON.stringify({ club: program.code, enrollmentData })
      })
    );

    let errorData = null;
    try {
      const {
        data: {error},
      } = yield call(enrollmentApi.enrollInProgram, {
        enrollmentForm: enrollmentData,
        programCode: program.code,
        childId: createChildNext?.id
      });
      errorData = error;
    } catch (ex) {
      console.log("exception", ex);
    }

    if (errorData)  {
      throw new Error(errorData);
    }

    yield put(
      createUsageLog({
        action: STUDENT_JOINED_CLUB,
        data: JSON.stringify({ club: program.code })
      })
    );

    yield put(loadCurrentUserAction());
    yield put(enrolledChild({enrolledChildError: false}));
  } catch(e) {
    yield put(showErrorMessage('Unable to create a child. Please try again.'));
    yield put(enrolledChild({enrolledChildError: true}));
    yield put(decrementInitialLoaders);
  }
}

export function* setParentConsent() {
  try {
    const { data: { acceptedParentConsentAt }} = yield call(usersApi.setParentConsent);
    
    if (!!acceptedParentConsentAt) {
      yield put(receivedParentConsent({ acceptedParentConsentAt, error: false }));
    }
    else {
      yield put(receivedParentConsent({ error: true }));
    }
  } catch {
    yield put(decrementInitialLoaders);
  }
}

export function* generateParentConsentPdf({ payload }) {
  try {
    const { data } = yield call(globalApi.generateParentConsentPdf, payload);
    yield call(downloadFile, data, 'Parent Consent', "application/pdf");
  } catch {
    yield put(decrementInitialLoaders);
  }
}