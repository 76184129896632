import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import ToolBar from '@material-ui/core/Toolbar';

import * as colors from "../../theme/colors";

const useStyles = makeStyles(theme => ({
  rootContainer: {
    backgroundColor: 'white',
    boxShadow: '0 1px 4px 0 #cbcbcb',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    maxWidth: '100%',
    paddingLeft: 35,
    position: 'fixed',
    top: 60,
    left: 0,
    zIndex: 10,

    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10
    },

    [theme.breakpoints.down('md')]: {
      '&$toolbarWide': {
        display: 'none'
      }
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  toolbarWide: {},
  toolbarGutters: {
    minHeight: 'auto',
    height: 'auto',
    justifyContent: 'left',
    padding: 0,
    width: '100%'
  },
  link: {
    fontSize: 16,
    margin: '0 8px',
    padding: '20px 0',
    position: 'relative',
    textDecoration: 'none',
    textAlign: 'center',

    '&:hover': {
      textDecoration: 'none',
    }

  },
  activeLink: {
    fontWeight: 700,
    '& $underline': {
      opacity: 1
    }
  },
  regularLink: {
    fontWeight: 600,
    '&:hover': {
      color: colors.darkThemeGreen,
    }
  },
  underline: {
    bottom: 0,
    height: 4,
    left: '50%',
    opacity: 0,
    position: 'absolute',
    transform: 'translateX(-50%)',
    transition: 'opacity .5s',
    width: '100%'
  }
}));

const ScrollMenu = ({ items, current, colorTheme }) => {
  const classes = useStyles();

  const onItemClick = (id) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });
      window.scrollBy(0, -150);
      window.history.pushState("", "", `#${id}`);
    }
  };

  return (
    <Container id="scroll_menu" className={clsx(items.length > 5 ? clsx(classes.rootContainer, classes.toolbarWide) : classes.rootContainer)}>
      <ToolBar id="toolbar_menu" classes={{gutters: classes.toolbarGutters}}>
        {items.map((i) => (
          <Link
            key={i.id}
            href={i.id}
            onClick={(evt) => { evt.preventDefault(); onItemClick(i.id); }}
            className={clsx(classes.link, i.id === current ? classes.activeLink : classes.regularLink)}
          >
            {i.heading}
            <div className={classes.underline} style={{ backgroundColor: colorTheme.b }}></div>
          </Link>
        ))}
      </ToolBar>
    </Container>
  );
};

export default ScrollMenu;
