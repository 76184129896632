import React from 'react';
import moment from "moment";
import { push as pushToUrl } from 'connected-react-router';
import { useGoogleLogin } from "@react-oauth/google";

import { useTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import { 
  withWidth,
  useMediaQuery,
  Tooltip,
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  Grid,
  Toolbar,
  Menu,
  MenuItem,
  MenuList,
  Link
} from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CheckIcon from "@material-ui/icons/Check";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LanguageIcon from '@material-ui/icons/Language';
import { connectTo } from '../utils/generic';
import * as globalActions from '../actions/global';
import * as authActions from '../actions/auth';
import * as trackingActions from '../actions/tracking';
import * as facilitatorsConsentAgreementActions from "../actions/facilitatorsConsentAgreement";

import { LANGUAGE_SELECTOR_CHANGE } from '../constants/trackingEvents';
import { FullscreenModal } from '../components/modals/fullscreen/FullscreenModal';
import { AuthorizeModal } from '../components/modals/authorize/authorizeModal';
import { FacilitatorsConsentModal } from  '../components/modals/members/consentModal';
import HqAvatar from '../components/avatar/avatar';
import UserDOBModal from '../components/modals/userProfile/userDOBModal';
import * as tokenStorage from '../tokenStorage';
import { checkIsClubsDomain } from '../utils/generic';
import { userIsAdult, userHasValidBirthday, userHasRoles, userIsAllowApplyOrAddChild } from "../utils/userUtils";
import { takeIfExists } from "../utils/local-storage";
import Roles from "../utils/permissions";

import GWCLogo from '../images/GWC_HQ_Logo.svg';
import GwcQaLogo from '../images/gwcqalogo.png';

import * as colors from '../theme/colors';
import CustomButton from './customButton/customButton';

const StyledMenu = withStyles({
  paper: {
  },
  list: {
    padding: 0,
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles({
  root: {
    color: colors.dartThemeBlueGray,
    fontSize: 16,
    fontWeight: 700,
    height: 65,
    minWidth: 185,
    display: 'flex',
    justifyContent: 'left',
    textTransform: 'uppercase',
    background: '#e5f8ff',
    '&:hover': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: colors.dartThemeBlueGray,
      },
      background: '#e5f8ff',
      color: colors.darkThemeGreen,
    },
  },
})(MenuItem);

const StyledTopMenuItem = withStyles({
  root: {
    color: colors.dartThemeBlueGray,
    fontSize: 16,
    fontWeight: 700,
    height: 65,
    minWidth: 185,
    display: 'flex',
    justifyContent: 'left',
    textTransform: 'uppercase',
    background: '#e5f8ff',
    '&:hover': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: colors.dartThemeBlueGray,
      },
      background: 'transparent',
      color: colors.darkThemeGreen,
    },
  },
})(MenuItem);

const menuStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      "& > li": {
        display: 'inline-flex',
        minWidth: 'auto',
      }
    },
    padding: 0,
    width: "100%",
  }
})
);
const ResponsiveMenu = ({ isMobile, ...props }) => {
  const classes = menuStyles();
  if (isMobile) {
    return <FullscreenModal isOpen={props.open} onClose={props.onClose}>
      {props.children}
    </FullscreenModal>
  }
  return (
    <Hidden smDown>
      <MenuList className={classes.root}>{props.children}</MenuList>
    </Hidden>
  );
};

const nestedMenuStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      "& > li": {
        minWidth: 'auto'
      }
    },
    [theme.breakpoints.down('md')]: {
      "& > li": {
        height: 50,
        justifyContent: 'left',
      }
    },
    padding: 0,
    width: '100%',
  },
  menuItemRoot: {
    color: 'white',
    fontSize: 16,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'white', // theme.palette.common.white,
      },
      color: 'white',
    },
  }
}));
const ResponsiveNestedMenu = ({ isMobile, ...props }) => {
  const classes = nestedMenuStyles();
  if (!props.open) {
    return null;
  }
  if (isMobile) {
    return (
      <MenuItem classes={{root: classes.menuItemRoot}}>
        <MenuList className={classes.root}>{props.children}</MenuList>
      </MenuItem>
    );
  }
  return <StyledMenu {...props} style={{ marginTop: 49 }}>{props.children}</StyledMenu>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 65,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: colors.darkThemeBlue,
  },
  menuButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  appBar: {
    height: 65,
    boxShadow: 'none',
    minWidth: '100%',
    background: (props) => props.navBackGroundColor,
    '&:hover': {
      background: colors.lightGradient,
    }
  },
  toolbar: {
    height: 65
  },
  buttonsBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: colors.dartThemeBlueGray,
    alignSelf: 'center',
    height: '100%',
  },
  navButton: {
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'none',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: 0,
    textTransform: 'uppercase',
    color: colors.dartThemeBlueGray,

    '& .MuiButton-label': {
      justifyContent: 'left',
    },
    '&:hover, &:active': {
      background: 'transparent',
      color: colors.darkThemeGreen,
    },
    '&:focus': {
      background: 'none',
    },
    "&.Mui-focusVisible": {
      outline: 'none',
      border: 'none',
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    }
  },
  mobileLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  menuItemBox: {
    [theme.breakpoints.up('md')]: {
      display: 'inline-flex',
      minWidth: 'auto',
      backgroundColor: 'transparent',
    },
    "&.Mui-focusVisible": {
      outline: `2px dotted ${colors.grey400}`,
      outlineOffset: -2
    },
  },
  globeIcon: {
    marginTop: -2,
    marginRight: 7
  },
  langCheckIcon: {
    marginTop: -2,
    marginRight: 10,
    color: colors.lightThemeGreen
  },
  langSpacer: {
    width: 33,
    height: 10,
    display: "inline-block"
  },
  menuCurrentPage: {
    width: "100%",
    height: 4,
    background: colors.darkThemeGreen,
    position: "absolute",
    bottom: -6
  },
  rightTopMenu: {
    textAlign: "right"
  },
  loginButton: {
    width: '100px',
    [theme.breakpoints.up('xl')]: {
      width: '120px',
    }
  },
  mainContentLink: {
    position: 'absolute',
    top: '0px',
    left: '0px'
  }
}));

const mainPath = '.girlswhocode.com';

const getCurrentLocale = (locales) => {
  if (!locales || locales.length === 0) {
    return;
  }
  const actualSubdomain = window.location.host.replace(mainPath, '');
  const filteredLocales = locales.filter(l => l.domain === actualSubdomain); // getting language by subdomain

  if (filteredLocales.length > 0) {
    return filteredLocales[0];
  }
}

const NavBar = (props) => {
  const {
    logout,
    authorizeParent,
    authorizeChild,
    auth: { currentRole, user, isUserLoading },
    languages,
    setHome,
    router,
    push,
    createTrackingLog,
    contentful,
    width,
    isMobile,
    setIsMobile,
    setFacilitatorsConsentAgreement,
    facilitatorsConsentAgreement: { isLoadingFacilitatorsConsentAgreement },
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [langAnchorEl, setLangAnchorEl] = React.useState(null);
  const [mobileEl, setMobileEl] = React.useState(null);
  const [children, setChildren] = React.useState(null);
  const [parents, setParents] = React.useState(null);
  const [passwordModal, setPasswordModal] = React.useState(false);
  const [selectedParent, setSelectedParent] = React.useState(null);
  const [navBackGroundColor, setNavBackGroundColor] = React.useState(colors.white);
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [labels, setLabels] = React.useState(null);

  const [mobileNavigation, setMobileNavigation] = React.useState(false);
  const [clubsWithoutFacilitatorConsent, setClubsWithoutFacilitatorConsent] = React.useState([]);
  const [openFacilitatorConsentModal, setOpenFacilitatorConsentModal] = React.useState(false);
  const [clubForConsent, setClubForConsent] = React.useState(null);
  const [userDOBModal, setUserDOBModal] = React.useState(false);
  const [addChildClicked, setAddChildClicked] = React.useState(false);

  const theme = useTheme();
  const classes = useStyles({navBackGroundColor});

  const _parentEmail = React.useRef(null); // useRef is used instead of useState because it's not visible inside google hook callback

  const open = Boolean(anchorEl);
  const openLang = Boolean(langAnchorEl);
  const openNavMobile = Boolean(mobileEl);

  const defaultlang = languages.find(e =>  e.isDefault === 'true');
  const hasAccountMenu = !!user && !!tokenStorage.getToken();
  const normalMode = useMediaQuery(theme.breakpoints.up('md'));
  const burgerMenuMode = useMediaQuery(theme.breakpoints.down('md'));

  const location = useLocation();
  const isCalendarPage = matchPath(location.pathname, {
    path: '/calendar',
    exact: true,
    strict: false
  });
  const isHomePage = matchPath(location.pathname, {
    path: '/home',
    exact: true,
    strict: false
  });
  const isProjectGalleryPage = matchPath(location.pathname, {
    path: '/project-gallery',
    exact: true,
    strict: false
  });
  const isLoginPage = matchPath(location.pathname, {
    path: '/login',
    exact: true,
    strict: false
  });
  const isLandingPage = matchPath(location.pathname, {
    path: '/',
    exact: true,
    strict: false
  });

  const isClubsDomain = checkIsClubsDomain();
  const tooltipText = "Skip to Content (Press Enter)";

  React.useEffect(() => {
    setIsMobile(!!isMobile);
  }, [isMobile]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    handleClose();

    setMobileNavigation(isMobile || !normalMode || burgerMenuMode);
  }, [router, width, normalMode, isMobile, burgerMenuMode, currentRole]);

  React.useEffect(() => {
    if (!contentful.hqConfig || !contentful.GlobalComponentQuery) {
      return;
    }
    
    const {
      hqConfig: { defaultProgram },
      GlobalComponentQuery: {
        header, membersPageLabels,
      }
    } = contentful;

    setLabels({ ...header, ...membersPageLabels });
    
    if(!user){
      return;
    }
    const { parents, children } = user;
    if (children) {
      setChildren(children);
    }

    if (parents) {
      setParents(parents)
    }

    const defaultProgramId = parseInt(defaultProgram);
    const isPartner = user.roles.filter((role) => role.roleId === 6);
    const userPrograms = user.roles
      .filter(
        (role) =>
          role.programId &&
          role.programId !== defaultProgramId &&
          !!role.program
      )
      .map((e) => e.programId);
    setShowCalendar(!!userPrograms.length || !!isPartner.length);
  }, [user, contentful]);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    const facilitatorConsentStored = takeIfExists("facilitatorConsent", Object);
    const currentClubsWithoutFacilitatorConsent = user.roles.filter(role => {
      return role.program?.needsFacilitatorConsent && (!facilitatorConsentStored || !facilitatorConsentStored[role.programId]);
    }).map(({ program }) => program);
    setClubsWithoutFacilitatorConsent(currentClubsWithoutFacilitatorConsent);
  }, [user]);

  React.useEffect(() => {
    if (!clubsWithoutFacilitatorConsent.length) return;

    const clubForConsent = clubsWithoutFacilitatorConsent[0];
    setClubForConsent(clubForConsent);
    setOpenFacilitatorConsentModal(true);
  }, [clubsWithoutFacilitatorConsent, user]);

  React.useEffect(() => {
    setUserDOBModal(!userHasValidBirthday(user) && userHasRoles(user, [Roles.Viewer, Roles.CommunityPartner]));
  }, [user?.birthDate]);

  window.addEventListener("scroll", function() {

    if (window.scrollY > 80 && navBackGroundColor != colors.lightGradient) {
      setNavBackGroundColor(colors.lightGradient);
    }
    if (window.scrollY <= 80 && navBackGroundColor != colors.white) {
      setNavBackGroundColor(colors.white);
    }
  });

  const handleMenu = event => {
    setNavBackGroundColor(colors.lightGradient);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleLang = event => {
    setNavBackGroundColor(colors.lightGradient);
    setLangAnchorEl(langAnchorEl ? null : event.currentTarget);
  }

  const handleClose = () => {
    if (anchorEl) setAnchorEl(null);
    if (langAnchorEl) setLangAnchorEl(null);
    if (mobileEl) setMobileEl(null);
    setNavBackGroundColor(colors.white);
  };

  const onLogoClick = () => {
    setHome(true);
  }

  const onChildClick = (child) => {
    const { id: childId, roles, kinshipId, birthDate } = child;
    const isInvalidDOB = !birthDate || !moment(birthDate).isValid();

    if (!child || !kinshipId || isInvalidDOB) {
      return;
    }

    if (roles && roles.length > 0) {
      authorizeChild({childId, parentId: user.id});
    } else {
      push(`/join-program/${childId}`);
    }
  }

  const onParentClick = (parent) => {
    if (parent.passwordNotSet) {
      _parentEmail.current = parent.email;
      login();
      return;
    }

    setSelectedParent(parent);
    setPasswordModal(true);
  }

  const onCloseConsentModal = () => {
    let facilitatorConsent = takeIfExists("facilitatorConsent", Object);

    if (!facilitatorConsent) {
      localStorage.setItem("facilitatorConsent", JSON.stringify({
        [clubForConsent.id]: {
          ['closed']: {
            date: new Date(),
          }
        }
      }));
    } else {
      facilitatorConsent = {
        ...facilitatorConsent,
        [clubForConsent.id]: {
          ...facilitatorConsent[clubForConsent.id],
          ['closed']: {
            date: new Date(),
          }
        },
      }
      localStorage.setItem("facilitatorConsent", JSON.stringify(facilitatorConsent));
    }
    setClubsWithoutFacilitatorConsent(clubsWithoutFacilitatorConsent.filter(club => club.id !== clubForConsent.id));
    setClubForConsent(null);
  }

  const onCloseParentModal = () => {
    setSelectedParent(null);
    setPasswordModal(false);
  }

  const authorizeParentFunc = ({password}) => {
    const childToken = tokenStorage.getToken();

    authorizeParent({ 
      login: selectedParent.email,
      password,
      childId: user.id,
      childToken,
    });

    if (!isUserLoading) {
      setSelectedParent(null);
      setPasswordModal(false);
    }
  }

  const showRightNavigation = event => {
    setNavBackGroundColor(colors.lightGradient);
    setMobileEl(event.currentTarget);
  }

  const changeLocale = (lang) => {
    const newLocation = getLocation(lang);

    if (window.location != newLocation) {
      createTrackingLog({
        event: LANGUAGE_SELECTOR_CHANGE,
        data: JSON.stringify({
          from: window.location.href,
          to: newLocation
        })
      });
      window.location.href = newLocation;
    }
  }

  const getLocation = (lang) => {
    const delimiter = '/';
    let parts = window.location.href.split(delimiter);

    parts = parts.map(e => {
      const isDomain = e.indexOf(mainPath);
      if (isDomain !== -1) {
        e = `${lang.domain}${mainPath}`;
      }
      return e;
    });

    return `${parts.join('/')}`;
  }

  const currentLocale = getCurrentLocale(languages);

  const responseGoogle = (response) => {
    const { access_token } = response;

    authorizeParent({ login: _parentEmail.current, accessToken: access_token });
    _parentEmail.current = null;
    push('/home');
  };

  const responseGoogleError = () => {
    // TODO: next line was commented out because qa-automation gets alert for test.hq.girlswhocode.com
    // This should be handled properly
    // alert('Please, make sure cookies are enabled for current application');
  };

  const login = useGoogleLogin({
    onSuccess: (res) => {
      responseGoogle(res);
    },
    onError: () => responseGoogleError(),
  });

  const selectedLangCode = currentLocale ? currentLocale.code : defaultlang ? defaultlang.code : '';

  const renderLanguagesMenu = ({ open }) => (
    <ResponsiveNestedMenu
      id="languages"
      anchorEl={langAnchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      style={{ marginTop: 49 }}
      isMobile={mobileNavigation}
    >
      {languages && languages.map(el =>
        <StyledMenuItem key={el.id} onClick={() => { handleClose(); changeLocale(el); }}>
          {el.code === selectedLangCode && <CheckIcon className={classes.langCheckIcon} />}
          {el.code !== selectedLangCode && <div className={classes.langSpacer} />}
          {el.title}
        </StyledMenuItem>)}
    </ResponsiveNestedMenu>
  );
  
  const onAddChild = () => { handleClose(); props.push('/home?addchild=true') }

  const showDOBModal = () => { setUserDOBModal(true); setAddChildClicked(true); renderDOBModal() }

  const renderDOBModal = () => {
    return (
      <UserDOBModal   
        onClose={() => {
          setUserDOBModal(false);
          setAddChildClicked(false);         
        }}
        userDOBModal={userDOBModal}
        callback={() => userIsAdult(user) && onAddChild()}
        message='In order to add a child you must be at least 18 years old.'
      />
    );
  };

  const renderProfileMenu = ({ open }) => hasAccountMenu && (
    <ResponsiveNestedMenu
      id="profileMenu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      isMobile={mobileNavigation}
      style={{ marginLeft: 16, marginTop: 49 }}
    >
      {children && children.map((child, index) => {
        return(<StyledMenuItem key={index} onClick={() => { handleClose(); onChildClick(child); }}>{child.firstName}</StyledMenuItem>);
      })}

      {parents && parents.map(e => {
        return(<StyledMenuItem onClick={() => { handleClose(); onParentClick(e); }}>{e.firstName}</StyledMenuItem>);
      })}
      {(userIsAdult(user) || userIsAllowApplyOrAddChild(user)) && 
      <StyledMenuItem onClick={() => userHasValidBirthday(user) ? onAddChild() : showDOBModal()}>Add Child</StyledMenuItem>}
      <StyledMenuItem onClick={() => { handleClose(); props.push('/user-profile') }} sel="view-profile">View Profile</StyledMenuItem>
      <StyledMenuItem onClick={() => { handleClose(); logout({logoutFromButton: true}) }} sel="logout">Logout</StyledMenuItem>
    </ResponsiveNestedMenu>
  );

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          <Grid container>
            <Box item
              width={isMobile || burgerMenuMode ? 0.95 : 270}
              className={mobileNavigation ? classes.mobileLogoContainer : classes.logoContainer}>
              <Box component={NavLink} to="/home" onClick={() => onLogoClick()}>
                <img
                  alt="Girls Who Code HQ"
                  style={{ maxHeight: 65, fontSize: 0, width: 200 }}
                  src={window._env_.REACT_APP_PRODUCTION_LOGO ? GWCLogo : GwcQaLogo}
                />
              </Box>
              <Tooltip title={tooltipText}>
                <Link aria-label={tooltipText} className={classes.mainContentLink} href="#maincontent"></Link>
              </Tooltip>  
            </Box>

            <Box item width={isMobile || burgerMenuMode ? 0.05 : "calc(100% - 270px)"}>
              <Box className={classes.buttonsBox}>
              <Hidden only={mobileNavigation ? [] : ['md', 'lg', 'xl']}>
                <IconButton
                    color="inherit"
                    aria-label="Main menu"
                    aria-expanded="false"
                    edge="start"
                    onClick={showRightNavigation}
                  >
                  <MoreVertIcon />
                </IconButton>
                </Hidden>
                <ResponsiveMenu
                  id="def"
                  anchorEl={mobileEl}
                  keepMounted
                  open={openNavMobile}
                  isMobile={mobileNavigation}
                  onClose={handleClose}>
                    <Grid container spacing={3} tabIndex="-1">
                      {!mobileNavigation &&
                      <Grid item xs={5}>
                        {hasAccountMenu &&
                          <StyledTopMenuItem disableRipple onClick={() => { handleClose(); }} className={mobileNavigation ? '' : classes.menuItemBox}>
                            <Button color="inherit" component={NavLink} to="/home" className={classes.navButton} disableRipple sel="programs" role="navigation">
                              My Programs
                              {!mobileNavigation && isHomePage && <div className={classes.menuCurrentPage} />}
                            </Button>
                          </StyledTopMenuItem>
                        }

                        {hasAccountMenu && showCalendar &&
                          <StyledTopMenuItem disableRipple onClick={() => { handleClose(); }} className={mobileNavigation ? '' : classes.menuItemBox}>
                            <Button color="inherit" component={NavLink} to="/calendar" className={classes.navButton} disableRipple sel="calendar" role="navigation">
                              My Calendar
                              {!mobileNavigation && isCalendarPage && <div className={classes.menuCurrentPage} />}
                            </Button>
                          </StyledTopMenuItem>
                        }
                      </Grid>}
                      <Grid item className={classes.rightTopMenu} xs={mobileNavigation ? 12 : 7}>
                        {mobileNavigation && hasAccountMenu &&
                        <StyledTopMenuItem disableRipple onClick={() => { handleClose(); }} className={mobileNavigation ? '' : classes.menuItemBox}>
                          <Button color="inherit" component={NavLink} to="/home" className={classes.navButton} disableRipple sel="programs" role="navigation">My Programs</Button>
                        </StyledTopMenuItem>}

                        {hasAccountMenu && showCalendar && mobileNavigation
                        && <StyledTopMenuItem disableRipple onClick={() => { handleClose(); }} className={mobileNavigation ? '' : classes.menuItemBox}>
                          <Button color="inherit" component={NavLink} to="/calendar" className={classes.navButton} disableRipple sel="calendar" role="navigation">My Calendar</Button>
                        </StyledTopMenuItem>}

                        {(isClubsDomain && hasAccountMenu || !isClubsDomain) && <StyledTopMenuItem disableRipple onClick={() => { handleClose(); }} className={mobileNavigation ? '' : classes.menuItemBox}>
                          <Button color="inherit" component={NavLink} to="/project-gallery" className={classes.navButton} disableRipple sel="project-gallery" role="navigation">
                            Project Gallery
                            {!mobileNavigation && isProjectGalleryPage && <div className={classes.menuCurrentPage} />}
                          </Button>
                        </StyledTopMenuItem>}

                        <StyledTopMenuItem
                          disableRipple
                          className={mobileNavigation ? '' : classes.menuItemBox}
                        >
                          <Button
                            color="inherit"
                            onClick={() => {
                              if (labels && labels.navBarHelpLink) {
                                window.open(labels.navBarHelpLink);
                              }
                            }}
                            className={classes.navButton}
                            disableRipple
                            sel="help"
                            role="navigation"
                          >Help
                          </Button>
                        </StyledTopMenuItem>

                        <StyledTopMenuItem
                          disableRipple
                          selected={openLang}
                          className={mobileNavigation ? '' : classes.menuItemBox}
                        >
                          <Button
                            aria-controls="customized-menu-lang"
                            color="inherit"
                            onClick={handleLang}
                            className={classes.navButton}
                            disableRipple
                            sel="lang"
                            aria-label="Language selector"
                            role="navigation"
                          >
                            <LanguageIcon className={classes.globeIcon} />

                            {selectedLangCode}
                            {openLang ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          </Button>
                        </StyledTopMenuItem>

                        {renderLanguagesMenu({ open: openLang && mobileNavigation })}

                        {hasAccountMenu &&
                        <StyledTopMenuItem
                          disableRipple
                          selected={open}
                          className={mobileNavigation ? '' : classes.menuItemBox}
                        >
                          <Button
                            aria-label="profile menu"
                            aria-controls="customized-menu"
                            color="inherit"
                            onClick={handleMenu}
                            className={classes.navButton}
                            disableRipple
                            sel="profile"
                            role="navigation"
                          >
                            <HqAvatar
                              user={user}
                              src={user.avatarName && user.avatarUrl}
                            />
                            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          </Button>
                        </StyledTopMenuItem>
                        }
                        {!hasAccountMenu && !isLoginPage && !isLandingPage && (
                          <StyledTopMenuItem disableRipple onClick={() => { handleClose(); }} className={mobileNavigation ? '' : classes.menuItemBox}>
                            <CustomButton
                              mode="secondary"
                              className={classes.loginButton}
                              onClick={() => push('/')}
                            >Log in</CustomButton>
                          </StyledTopMenuItem>
                        )}
                        {renderProfileMenu({ open: open && mobileNavigation, user })}
                      </Grid>
                    </Grid>
                </ResponsiveMenu>
                {renderLanguagesMenu({ open: openLang && !mobileNavigation })}
                {renderProfileMenu({ open: open && !mobileNavigation, user })}
              </Box>
              <AuthorizeModal 
                isOpen={passwordModal} 
                onClose={onCloseParentModal} 
                onSave={authorizeParentFunc} 
                parent={selectedParent}/>
              {openFacilitatorConsentModal && clubForConsent && <FacilitatorsConsentModal
                onSubmit={() => {
                  setFacilitatorsConsentAgreement({ programId: clubForConsent.id });
                  onCloseConsentModal();
                }}
                onClose={() => {
                  onCloseConsentModal();
                }}
                labels={labels.consentAgreementModalText}
                programName={clubForConsent.name}
                isLoading={isLoadingFacilitatorsConsentAgreement}
                isOpen={openFacilitatorConsentModal}
              />}
            </Box>
          </Grid>
        </Toolbar>
      </AppBar>
      {user && userDOBModal && addChildClicked && renderDOBModal()} 
    </div>
  );
}

export default connectTo(
  (state) => {
    return {
      auth: state.auth,
      languages: state.contentful.languages,
      contentful: state.contentful,
      router: state.router,
      facilitatorsConsentAgreement: state.facilitatorsConsentAgreement,
    }
  },
  {
    ...authActions,
    ...globalActions,
    ...trackingActions,
    ...facilitatorsConsentAgreementActions,
    push: pushToUrl,
  },
  withWidth()(NavBar),
);
