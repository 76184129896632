import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";

import { connectTo } from "../../../../utils/generic";
import { getTheme } from "../../../../utils/colorTheme";
import * as curriculumActions from "../../../../actions/curriculum";
import * as authActions from "../../../../actions/auth";
import * as globalActions from "../../../../actions/global";
import * as userActivitiesInfoActions from '../../../../actions/userActivitiesInfo';
import * as communityPartnershipsActions from "../../../../actions/communityPartnerships";

import NavBar from "../../HorizontalNavigation"
import Activity from "../../../Curriculum/Activity";
import CardsGrid from "../../../Curriculum/CardsGrid";

import * as trackingActions from "../../../../actions/tracking";
import * as contentfulActions from "../../../../actions/contentful";

import * as colors from "../../../../theme/colors";

import { ACTIVITY_DONE_BUTTON_CLICKED } from "../../../../constants/trackingEvents";

import { useTitle } from "../../../../utils/pageTitles";

const titleStyles = makeStyles((theme) => ({
  titleBox: {
    width: "100%",
  },
  root: {
    width: "100%",
  },
  main: {
    width: '100%',
    height: "100%",
    minHeight: 'calc(100vh - 135px)',
  },
  titleContent: {
    margin: "auto",
    maxWidth: 1280,
    display: "flex",
    paddingTop: 10,
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  nameTitle: {
    color: colors.darkBlue100,
    fontSize: 32,
    fontWeight: "bold",
    textTransform: "uppercase",
    marginTop: 5,
    [theme.breakpoints.down("xs")]: {
      fontSize: 22,
    },
  },
  linksBlock: {
    display: "flex",
    marginTop: 15,
    flexDirection: "column",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      margin: "10px 5px 0 20px",
      overflow: "hidden",
      boxOrient: "vertical",
      lineClamp: 2,
      display: "-webkit-box",
      minHeight: "auto",
    },
  },
  breadCrumbLink: {
    color: colors.darkThemeGreen,
    fontWeight: "bold",
    fontSize: 14,
    textDecoration: "none",
    '&:hover': {
      color: colors.lightThemeGreen,
    },
  },
  breadCrumbLastLink: {
    color: colors.lightGrey,
    fontWeight: "bold",
    fontSize: 14,
    textDecoration: "none",
  },
}));

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
  },
  main: {
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

var currentActivityInfo;

const getRouteInfo = (path, nodes, parentNode) => {
  const plainNodes = Object.values(nodes);
  const current = plainNodes.find((e) => e.slug === path);
  const currentIndex = plainNodes.indexOf(current);
  const isLast = currentIndex + 1 === plainNodes.length;
  const nextItem = isLast ? null : plainNodes[currentIndex + 1];
  return { plainNodes, current, parentNode, path, isLast, nextItem };
};

const getNextItem = (curriculum, currentNode, parts) => {
  if (!curriculum || !currentNode || !parts) {
    return;
  }
  let current = curriculum;
  let nodeArray = [];
  for (var p of parts) {
    const node = getRouteInfo(p, current.nodes, current);
    current = node.current;
    nodeArray.push(node);
  }
  nodeArray = nodeArray.reverse();
  const parentNode = nodeArray.find((e) => !e.isLast);

  if (parentNode) {
    const { nextItem } = parentNode;
    return nextItem;
  } else {
    return nodeArray[nodeArray.length - 1].current;
  }
};

const getCurrentNode = (curriculum, path) => {
  let next = null;
  let previous = null;

  if (!curriculum) return { current: null, next, previous };

  const parts = path
    .replace("/community-partnership/resources/community-partner-resources", "")
    .split("/")
    .filter((p) => p.length > 0);

  let current = curriculum;
  let parentNode = null;

  const chain = [];

  for (const p of parts) {
    if (!current) {
      continue;
    }

    const plainNodes = Object.values(current.nodes);
    for (let i = 0; i < plainNodes.length; i++) {
      const n = plainNodes[i];

      if (n.slug === p) {
        chain.push(n);

        if (i !== 0) {
          previous = plainNodes[i - 1];
        } else {
          previous = null;
        }

        next = getNextItem(curriculum, current, parts);
      }
    }

    parentNode = {
      ...current,
    };
    current = current.nodes[p];
  }

  return {
    chain,
    current,
    next,
    parentNode,
    previous,
    parts,
  };
};

const getContentSectionsSections = (activity) => {
  if (!activity || !activity.contentSections) {
    return null;
  }

  const items = activity.contentSections;
  return items.length > 0 ? items : null;
};

const CommunityPartnerhipResources = (props) => {
  const {
    auth: { currentRole, user },
    contentful,
    curriculum,
    history: { push },
    location,
    loadGlobalContent,
    setActivityLog,
    userActivitiesInfo,
    createTrackingLog,
    createUsageLog,
    deleteMyActivityProgressInPartnership,
    getMyUserActivitiesByPartnership,
    csItems,
    createOrUpdateCSItem,
    getAllCsItemStatuses,
    communityPartnership,
    isLoading,
  } = props;

  useTitle("cp-resources");

  const [activityStep, setActivityStep] = React.useState(0);
  const [partnership, setPartnership] = React.useState(null);
  const [currentTab, setCurrentTab] = React.useState('Resources');
  const [activity, setActivity] = React.useState(
    curriculum && curriculum.currentActivity ? curriculum.currentActivity : null
  );

  React.useEffect(() => {
    // dont load contentfull if it was already loaded
    if (!contentful || !!contentful.hqConfig) {
      return;
    }
    loadGlobalContent();
  }, []);

  const classes = useStyles();

  const { hqConfig } = contentful;
  const currentCurriculum = contentful.curriculums[hqConfig.communityPartnershipResourcesCurriculum]?.nodes['community-partner-resources'];

  const {
    chain,
    current: currentNode,
    next,
    parentNode,
    previous,
  } = getCurrentNode(currentCurriculum, location.pathname);

  React.useEffect(() => {
    if (!currentNode) return;

    if (
      currentNode.type === "activity" ||
      currentNode.nodeType === "activity"
    ) {
      // determining current step if has was provided
      if (currentNode.contentSections && location.hash && location.hash.length > 1) {
        const cSections = currentNode.contentSections;

        const step = cSections.findIndex(r => r.id === location.hash.replace('#', ''))
        setActivityStep(step !== -1 ? step : 0);
      } else {
        setActivityStep(0);
      }

      const existingActivity = curriculum.activities[currentNode.slug];
      if (
        !existingActivity ||
        (!!currentRole && currentRole.roleId !== existingActivity.roleId)
      ) {
        if (!!currentRole) {
          props.loadActivity({
            slug: currentNode.slug,
            permissionContext: {
              roleId: currentRole.roleId,
            },
            noCache: true,
          });
        }
      } else {
        props.setCurrentActivity(existingActivity);
      }

    } else {
      props.setCurrentActivity(null);
    }
  }, [currentNode, currentRole]);

  React.useEffect(() => {
    if (!currentNode) return;

    setActivity(curriculum.currentActivity);
  }, [curriculum.currentActivity, currentNode]);

  React.useEffect(() => {
    if (!currentRole) return;
    if (isLoading || !currentRole.cpOrganizationId) {
      setPartnership(null);

      return;
    }

    if (!communityPartnership && !partnership) {
      return;
    }

    setPartnership(communityPartnership);

  }, [isLoading, user, communityPartnership]);

  const sendActivityLog = () => {
    if (!currentNode || !curriculum.currentActivity) return;
    const contentSections = getContentSectionsSections(curriculum.currentActivity);

    if (contentSections && contentSections.length > 0) {
      const currentActivity = {
        userId: user.id.toString(),
        partnershipId: currentRole.cpOrganizationId.toString(),
        contentSection: contentSections[activityStep || 0].id,
        isViewed: true,
        title: curriculum.currentActivity.slug,
        path: location.pathname,
      };

      setActivityLog({
        ...currentActivity,
        activitySetSlug: parentNode && parentNode.type === 'set' || parentNode.nodeType === 'set' ? parentNode.slug : null,
        duration: null,
      });
      currentActivityInfo =  {
        currentActivity,
        parentNode
      };
    } else {
      currentActivityInfo = null;
    }
  }

  React.useEffect(() => {
    return () => {
      if (!currentActivityInfo) {
        return;
      }

      const { parentNode, currentActivity } = currentActivityInfo;
      setActivityLog({
        ...currentActivity,
        activitySetSlug: parentNode && parentNode.type === 'set' || parentNode.nodeType === 'set' ? parentNode.slug : null,
      });
    }
  }, []);

  React.useEffect(() => {
    if (!currentRole || !currentRole) {
      return;
    }

    if (currentRole.partnership && currentRole.partnership.id) {
      getMyUserActivitiesByPartnership({ id: currentRole.partnership.id });
    }
    getAllCsItemStatuses();
  }, [currentNode]);

  const exitActivity = () => {
    const pathParts = location.pathname.split('/');
    const newLocation = pathParts.slice(0, pathParts.length - 1).join('/');
    push(newLocation);
  };

  const activityDoneClick = (next) => {
    if (!userActivitiesInfo) {
      return;
    }

    const items = userActivitiesInfo.filter((r) => r.title === activity.slug);

    if (items.length > 0 && items[0].isDone) {
      if (next && next.path) {
        push(`/community-partnership/resources${next.path}`);
      }
    }

    const existingItem =
      items.length > 0
        ? {
            userId: items[0].userId.toString(),
            cpOrganizationId: items[0].cpOrganizationId
              ? items[0].cpOrganizationId.toString()
              : null,
            isViewed: items[0].isViewed,
            title: items[0].title,
            isDone: true,
            path: location.pathname,
            activitySetSlug: parentNode && parentNode.type === 'set' || parentNode.nodeType === 'set' ? parentNode.slug : null,
          }
        : {
            userId: user.id.toString(),
            cpOrganizationId: currentRole.partnership
              ? currentRole.cpOrganizationId.toString()
              : null,
            title: curriculum.currentActivity.slug,
            isDone: true,
            path: location.pathname,
            activitySetSlug: parentNode && parentNode.type === 'set' || parentNode.nodeType === 'set' ? parentNode.slug : null,
          };

    delete existingItem.id;
    currentActivityInfo = null;

    setActivityLog(existingItem);

    createTrackingLog({
      event: ACTIVITY_DONE_BUTTON_CLICKED,
      data: JSON.stringify({ data: window.location.pathname }),
    });

    if (next && next.path) {
      push(`/community-partnership/resources${next.path}`);
    }
  };

  const renderTitle = (titleTheme, titleClasses) => {
    if (chain?.length) {
      return (
        <Box className={titleClasses.titleBox}>
          <Box className={titleClasses.titleContent}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box className={titleClasses.linksBlock}>
                <Breadcrumbs
                  className={titleClasses.breadCrumbLink}
                  separator=" / "
                  aria-label="breadcrumb"
                >
                  <NavLink
                    className={titleClasses.breadCrumbLink}
                    color="inherit"
                    to={`/community-partnership/resources/community-partner-resources`}
                  >
                    Resources
                  </NavLink>
                  {chain &&
                    chain.map(
                      (c, index) =>
                        index !== chain.length - 1 && (
                          <NavLink
                            key={index}
                            id={c.id}
                            className={titleClasses.breadCrumbLink}
                            color="inherit"
                            to={`/community-partnership/resources${c.path}`}
                          >
                            {c.name}
                          </NavLink>
                        )
                    )
                  }
                    <Typography className={titleClasses.breadCrumbLastLink}>
                      {currentNode.name}
                    </Typography>
                </Breadcrumbs>
                <Typography className={titleClasses.nameTitle}>
                  {currentNode?.code === hqConfig.communityPartnershipResourcesCurriculum ? 'RESOURCES' : currentNode.name}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )
    }

    else {
      return (
        <Box className={titleClasses.titleBox}>
          <Box className={titleClasses.titleContent}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box className={titleClasses.linksBlock}>
                <Typography className={titleClasses.nameTitle}>
                  RESOURCES
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )
    }
  };

  const renderContent = () => {

    if (
      !!currentNode &&
      (currentNode.type === "activity" ||
      currentNode.nodeType === "activity")
    ) {
      return (
        <Activity
          roleId={currentRole && currentRole.roleId}
          programCode={currentRole && currentRole.partnership && currentRole.partnership.code}
          program={currentRole && currentRole.partnership}
          communityPartnershipLink={'/community-partnership/resources'}
          item={activity}
          next={next}
          pageLabels={
            contentful.GlobalComponentQuery
              ? contentful.GlobalComponentQuery.activityPageLabels
              : null
          }
          parentNode={parentNode}
          previous={previous}
          createUsageLog={createUsageLog}
          setDone={activityDoneClick}
          activityStep={activityStep}
          sendActivityLog={sendActivityLog}
          isDone={
            activity &&
            userActivitiesInfo &&
            userActivitiesInfo.filter(
              (r) => r.title === activity.slug && r.isDone
            ).length > 0
          }
          onExit={exitActivity}
          currentNode={getCurrentNode(currentCurriculum, location.pathname)}
          csItems={csItems}
          createOrUpdateCSItem={createOrUpdateCSItem}
        />
      );
    } else {
      return (
        <CardsGrid
          item={currentNode}
          pageLabels={
            contentful.GlobalComponentQuery
              ? contentful.GlobalComponentQuery.activityPageLabels
              : null
          }
          defaultActivityIcon={
            contentful.GlobalComponentQuery
              ? contentful.GlobalComponentQuery.defaultActivityIcon
              : null
          }
          previous={previous}
          parentNode={parentNode}
          createUsageLog={createUsageLog}
          communityPartnershipLink={'/community-partnership/resources'}
          userActivitiesInfo={userActivitiesInfo}
          isFacilitator={true}
          currentRole={currentRole}
          push={push}
          isPartnership={true}
          deleteMyActivityProgressInPartnership={deleteMyActivityProgressInPartnership}
        />
      );
    }
  };

  window.onscroll = function () {
    if (
      currentNode &&
      (currentNode.type === "activity" || currentNode.nodeType === "activity")
    ) {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        if (!activity) return;

        const items =
          activity && userActivitiesInfo
            ? userActivitiesInfo.filter((r) => r.title === activity.slug)
            : [];

        if (items.length > 0 && items[0].isViewed) return;
        
        const existingItem =
          items.length > 0
            ? {
                ...items[0],
                isDone: true,
              }
            : {
                userId: user.id.toString(),
                cpOrganizationId: currentRole.partnership ? currentRole.cpOrganizationId.toString() : null,
                title: activity.slug,
                isDone: false,
                isViewed: true,
              };

        delete existingItem.id;

        if (existingItem.title === null) return;

        existingItem.cpOrganizationId = existingItem.cpOrganizationId ? existingItem.cpOrganizationId.toString() : null;
        existingItem.userId = existingItem.userId.toString();

        setActivityLog(existingItem);
      }
    }
  };

  let nodeTheme = getTheme(
    activity && activity.activityType
      ? activity.activityType.colorTheme
      : null);

  if (currentNode && currentNode.setType && currentNode.setType.colorTheme) {
    nodeTheme = getTheme(currentNode.setType.colorTheme);
  }

  const titleClasses = titleStyles({ themeFont: nodeTheme.f });

  const handlePartnershipChange = () => {
    push('/community-partnership');
  }

  return (
    <div className={classes.root}>
      <main className={classes.main}>
      <NavBar
        partnership={partnership}
        onSelectNode={handlePartnershipChange}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
      />
        {renderTitle(nodeTheme, titleClasses)}
        {renderContent()}
      </main>
    </div>
  );
};

export default connectTo(
  (state) => ({
    curriculum: state.curriculum,
    auth: state.auth,
    contentful: state.contentful,
    userActivitiesInfo: state.userActivitiesInfo.myProgress,
    csItems: state.userActivitiesInfo.csItems,
    ...state.communityPartnerships,
  }),
  {
    ...curriculumActions,
    ...trackingActions,
    ...globalActions,
    ...authActions,
    ...contentfulActions,
    ...userActivitiesInfoActions,
    ...communityPartnershipsActions,
  },
  CommunityPartnerhipResources
);
