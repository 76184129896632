import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as momentTimezone from "moment-timezone";
import { push as pushToRoute } from "connected-react-router";
import clsx from "clsx";

import { Grid, Typography, IconButton } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import CssBaseline from '@material-ui/core/CssBaseline';
import InfoIcon from '@material-ui/icons/Info';

import { connectTo } from "../../utils/generic";
import Roles, { permissionsByRole } from "../../utils/permissions";
import * as actions from "../../actions/clubProfile";
import * as trackingActions from "../../actions/tracking";
import * as calendarActions from "../../actions/calendar";
import CustomButton from "../../components/text-button/text-button";
import PageTitle from "../../components/page-title/pageTitle";
import { getCommunityPartnerships } from "../../api/bl-query/communityPartnerships.query";

import {
  CLUB_PROFILE_EDIT,
  CLUB_PROFILE_VIEW,
  CLUB_PROFILE_UPDATE_SHIPPING_ADDRESS,
  CLUB_PROFILE_UPDATE_PARTNER_AFFILIATION,
} from "../../constants/trackingEvents";

import BasicInfoCard from "./clubProfileTiles/BasicInfoCard";
import ScheduleCard from "./clubProfileTiles/ScheduleCard";
import FacilitatorCard from "./clubProfileTiles/FacilitatorCard";
import MembersCard from "./clubProfileTiles/MembersCard";
import ViewerCard from "./clubProfileTiles/ViewersCard";
import PreferencesCard from "./clubProfileTiles/PreferencesCard";
import ClubFundsCard from "./clubProfileTiles/ClubFundsCard";
import TshirtsCard from "./clubProfileTiles/TshirtsCard";
import { EditClubInfo } from "./editClubInfo";
import { InviteFacilitatorsModal } from "../../components/modals/clubProfile/inviteFacilitatorsModal";
import { AddLeadershipModal } from "../../components/modals/clubProfile/addLeadershipModal";
import { EditUserRoleLabelModal } from "../../components/modals/clubProfile/editUserRoleLabelModal";
import * as programsApi from "../../api/programs";
import * as userRolesApi from "../../api/userRoles";
import * as membersActions from '../../actions/members';
import * as notificationActions from '../../actions/notification';
import { PROGRAM_TYPES } from '../../utils/constants';
import { sendUserInvitation } from "../../api/userInvitations";
import * as colors from "../../theme/colors";
import { TooltipWrapper } from "../../components/tooltip/tooltip";

import { useTitle } from "../../utils/pageTitles";
import NavBar from "../HorizontalNavigation";
import Footer from "../../containers/Footer/footer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  main: {
    width: '100%',
    margin: "auto auto",
    height: "100%",
    minHeight: 'calc(100vh - 132px)',
    paddingBottom: 15,
    paddingTop: 50,
    [theme.breakpoints.down('md')]: {
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 100,
    },
  },
  containerBox: {
    marginTop: 20,
  },
  membersGrid: {
    marginTop: 20,
  },
  header: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 15
  },
  gridItem: {
    marginTop: 15,
    [theme.breakpoints.down("xs")]: {
      margin: "10px 0",
    },
  },
  boxContainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  basicGrid: {
    width: "49%",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: 0,
    },
  },
  membersGrid: {
    width: "49%",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  editClubBtn: {
    '&:hover': {
      color: colors.lightThemeGreen,
    },
  },
  successAlert: {
    backgroundColor: colors.darkThemeGreen,
    color: "#fff",
  },
  errorAlert: {
    backgroundColor: colors.red3Theme,
    color: "#fff",
  },
  incentivesGridItem: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  incentivesItem: {
    [theme.breakpoints.up("lg")]: {
      marginRight: 10,
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: 10,
    },
  },
  clubIncentiveText: {
    fontSize: 24,
    fontWeight: "bold",
  },
  clubIncentiveTextContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  infoIcon: {
    color: colors.darkThemeBlueGray,
    marginBottom: 2.5,
    cursor: "text",
    "&:hover": {
      cursor: "text",
    },
  },
}));

const getCurrDate = (dateObj) => {
  var month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  var date = ("0" + dateObj.getDate()).slice(-2);
  var year = dateObj.getFullYear();
  return year + "-" + month + "-" + date;
};

const editableFields = [
  "scheduleEnabled", "membersCountEnabled", "meetingTypeEnabled", "shippingAddressEnabled",
  "virtualConferenceLinkEnabled", "socialMediaEnabled", "communityPartnerAffiliationEnabled", 
];

const ClubProfile = ({
  getClubInfo,
  auth,
  getMembers,
  createTrackingLog,
  updateClubInfo,
  updateClubShipping,
  updateVirtualLinkOnly,
  updateSocialMedia,
  updatePreferences,
  updateCommunityPartnershipAffilation,
  isUpdatingPreferences,
  program,
  showErrorMessage,
  showSuccessMessage,
  isLoading,
  contentful,
  members: {
    members: clubMembers,
  },
  contentful: {
    hqConfig: { defaultProgram },
  },
  match: {
    params: { programCode },
  },
  loadCalendarsData,
  calendarData,
  getProgramsAndMembersInfo,
  push,
  studentView,
  roleLabels,
  createUserRoleLabel,
  isLoadingUserRoleLabels,
  isUpdatingUserRoleLabels,
  deleteUserRoleLabel,
  userRoleLabels,
  getRoleLabels,
  getUserRoleLabels
}) => {
  useTitle("program-info");

  const { user, currentRole } = auth;
  const classes = useStyles();

  const [club, setClub] = useState(null);
  const [clubProfileLabels, setClubProfileLabels] = useState(null);
  const [communityPartners, setCommunityPartners] = useState([]);
  const [userTimeZone, setUserTimeZone] = useState(momentTimezone.tz.guess());
  const [isEditable, setIsEditable] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isViewer, setIsViewer] = useState(false);
  const [inviteModalOpened, setInviteModalOpened] = React.useState(null);
  const [rolePermissions, setRolePermissions] = React.useState({});
  const [programs, setPrograms] = useState([]);
  const [programMembers, setProgramMembers] = useState([]);
  const [isLoadingProgramMembers, setIsLoadingProgramMembers] = useState(false);
  const [addLeadershipModalOpened, setAddLeadershipModalOpened] = useState(false);
  const [editedUserRoleLabel, setEditedUserRoleLabel] = useState(null);
  const [programTypeDetails, setProgramTypeDetails] = useState(null);

  useEffect(() => {
    if (!currentRole) return;
    getRoleLabels(currentRole.program.id);
  }, [currentRole]);

  useEffect(() => {
    if (!currentRole || !!editedUserRoleLabel || !!isUpdatingUserRoleLabels) return;
    getUserRoleLabels(currentRole.program.id);
  }, [isUpdatingUserRoleLabels]);

  useEffect(() => {
    if (!contentful || !contentful.GlobalComponentQuery) {
      return;
    }

    const {
      GlobalComponentQuery: { clubsProfileLabels },
      hqConfig: { defaultProgram },
    } = contentful;

    if (defaultProgram) {
      getClubInfo({ programCode, defaultProgram });
    }
    const defaultProgramId = parseInt(defaultProgram);
    const userProgramIds = user.roles
      .filter(
        (role) =>
          role.programId &&
          role.programId !== defaultProgramId &&
          !!role.program
      )
      .filter((e) => e.program && !e.program.completed && e.program.active)
      .map((e) => e.programId);

    getProgramsAndMembersInfo({ userProgramIds, loadProgramsOnly: true });

    const selectedRole = user.roles.find((r) => {
      return r.program && r.program.code === programCode;
    });

    if (!selectedRole) {
      push("/home");
      return;
    }

    setClubProfileLabels(clubsProfileLabels);
    return () => {
      setClub({});
    };
  }, []);

  useEffect(() => {
    if (!user || !program) return;

    const { roles } = user;

    const programRole =
      user && roles.find((r) => r.programId == parseInt(program.id));
    const canEdit =
      programRole && permissionsByRole[programRole.roleId].editProgram;
    const currentProgramTypeDetails = program?.programTypeDetails;
    setProgramTypeDetails(currentProgramTypeDetails);
    const editableFieldsExists = currentProgramTypeDetails && editableFields.some(field => field in currentProgramTypeDetails && !!currentProgramTypeDetails[field]);

    setIsEditable(!studentView && program.programType !== PROGRAM_TYPES.SPP && canEdit && editableFieldsExists);
    setIsViewer(programRole && programRole.roleId === Roles.Viewer);

    loadCalendarsData({
      startDate: getCurrDate(new Date()),
      pageNumber: 1,
      filters: {
        programIds: [programRole.programId], showPublic: false
      },
    });
    const action = canEdit ? CLUB_PROFILE_EDIT : CLUB_PROFILE_VIEW;
    createTrackingLog({
      event: action,
    });
    
    setClub(program);

    if (user.timezone) {
      setUserTimeZone(user.timezone);
    }
    
    const permissions = currentRole.permissions ?? {};
    setRolePermissions(!studentView ? permissions : {});

    const selectedRole = roles.find((r) => {
      return r.program && r.program.code === programCode;
    });

    const defaultProgramId = parseInt(defaultProgram);
    const userPrograms = user.roles.filter(
      (role) =>
        role.programId && !!role.program &&
        [defaultProgramId, selectedRole.programId].indexOf(role.programId) === -1
    ).map((e) => e.program)
      .map((el) => {
        return {
          id: el.id,
          label: el.name,
        };
      });

    setPrograms(userPrograms);
  }, [user, program, studentView]);

  const loadPartnerships = async () => {
    const result = await getCommunityPartnerships();

    const sortedCPList = result.sort((a, b) => {
      const aName = a.name ? a.name.toLowerCase(): null;
      const bName = b.name ? b.name.toLowerCase(): null;
      return (aName > bName ? 1 : -1);
    });

     setCommunityPartners(sortedCPList);
  };

  const updateClub = (changedData) => {
    const { id } = club;
    updateClubInfo({
      id,
      data: changedData
    });
  };

  const saveShippingAddress = (data) => {
    const { id } = club;

    createTrackingLog({
      event: CLUB_PROFILE_UPDATE_SHIPPING_ADDRESS,
      data: data
        ? JSON.stringify({ ...data, id })
        : "",
    });

    updateClubShipping({
      id,
      shippingData: { ...data }
    });
  };

  const saveCommunityPartnerAffilation = (data) => {
    const { id, code } = club;
    const payload = { ...data, clubId: id, code};

    createTrackingLog({
      event: CLUB_PROFILE_UPDATE_PARTNER_AFFILIATION,
      data: data
        ? JSON.stringify(payload)
        : "",
    });

    updateCommunityPartnershipAffilation(payload);
  };

  const saveVirtualLink = (data) => {
    updateVirtualLinkOnly({
      id: club.id,
      data: data,
    });
  };

  const saveSocialMedia = (data) => {
    updateSocialMedia({
      id: club.id,
      data: data,
    });
  };

  const isClub = programTypeDetails?.programType === PROGRAM_TYPES.CLUB;
  const isCollegeLoop = programTypeDetails?.programType === PROGRAM_TYPES.COLLEGE_LOOP;

  /* eslint-disable */
  const loadProgramMembers = (programIds, eventId) => {
    setIsLoadingProgramMembers(true);
    Promise.all(programIds.map((id) => {
      return programsApi
        .getProgramMembers({ id: parseInt(id) });
    })).then(values => {
      const programMembersList = { ...programMembers };

      // show facilitators have not participated already in current club;
      values.map(e => {
        if (!!e.data && !!e.data.length) {
          programMembersList[e.data[0].programId] = e.data.filter(x => !club.facilitators.find(y => y.id === x.userId));
        }
      });
      setProgramMembers(programMembersList);
      setIsLoadingProgramMembers(false);
    })
      .catch(() => {
        setIsLoadingProgramMembers(false);
      });
  };

  const filteredStudentsCount = club?.students?.filter(student => !student.deletedAt && !student.incognito && (!club.anonymousUser || !!student.lastLogin)).length || 0;
  const showClubIncentives = rolePermissions?.showClubIncentives && club && club.active && club.programType === PROGRAM_TYPES.CLUB && !!club.fundsTotalQualifiedAmount && filteredStudentsCount >= 3;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavBar />
      <main className={classes.main}>
        {!isEditMode ? (
          <Container>
            <Box className={classes.header}>
              <PageTitle title="Program Info" isLoading={isLoading} />
              {isEditable && (
                <CustomButton
                  className={classes.editClubBtn}
                  onClick={() => {
                    setIsEditMode(true);

                    if (!communityPartners || !communityPartners.length) {
                      loadPartnerships();
                    }
                  }}
                >
                  Edit details
                </CustomButton>
              )}
            </Box>
            {!isLoading && (
              <Box className={classes.boxContainer}>
                <Grid
                  container
                  direction="column"
                  className={classes.basicGrid}
                >
                  {programTypeDetails?.basicInfoCardEnabled && <Grid
                    className={classes.gridItem}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <BasicInfoCard
                      club={club || {}}
                      isEditable={isEditable}
                      communityPartners={communityPartners}
                      saveShippingAddress={saveShippingAddress}
                      saveSocialMedia={saveSocialMedia}
                      saveVirtualLink={saveVirtualLink}
                      saveCommunityPartnerAffilation={saveCommunityPartnerAffilation}
                      labels={clubProfileLabels}
                      rolePermissions={rolePermissions}
                      loadPartnerships={loadPartnerships}
                      programTypeDetails={programTypeDetails}
                    />
                  </Grid>}
                  <Grid
                    className={classes.gridItem}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <FacilitatorCard
                      isClub={isClub}
                      isCollegeLoop={isCollegeLoop}
                      club={club || {}}
                      push={push}
                      rolePermissions={rolePermissions}
                      onInviteFacilitatorsClick={() => {
                        setInviteModalOpened(true);
                      }}
                      onAddLeadershipClick={() => {
                        setAddLeadershipModalOpened(true);
                      }}
                      roleLabels={roleLabels}
                      setEditedUserRoleLabel={setEditedUserRoleLabel}
                      userRoleLabels={userRoleLabels}
                      programTypeDetails={programTypeDetails}
                    />
                  </Grid>
                  {club && club.viewers && !!club.viewers.length && (
                    <Grid
                      className={classes.gridItem}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <ViewerCard
                        club={club || {}}
                        push={push}
                        isViewer={isViewer}
                        isEditable={isEditable}
                        viewerHeader={programTypeDetails?.viewerHeader}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  direction="column"
                  className={classes.membersGrid}
                >
                  {programTypeDetails?.scheduleEnabled && <Grid
                    className={classes.gridItem}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <ScheduleCard
                      club={club || {}}
                      calendarData={calendarData}
                      userTimeZone={userTimeZone}
                      labels={calendarData.calendarLabels}
                      programs={calendarData.programs}
                      push={push}
                      programTypeDetails={programTypeDetails}
                    />
                  </Grid>}
                  {programTypeDetails?.membersCountEnabled && <Grid
                    className={classes.gridItem}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <MembersCard
                      club={club || {}}
                      push={push}
                      isEditable={isEditable}
                      filteredStudentsCount={filteredStudentsCount}
                      labels={clubProfileLabels}
                      rolePermissions={rolePermissions}
                    />
                  </Grid>}
                  {showClubIncentives && clubProfileLabels && <Box className={classes.clubIncentiveTextContainer}>
                    <Typography component="p" className={classes.clubIncentiveText}>
                      {clubProfileLabels.clubBenefitsText}
                    </Typography>
                    <TooltipWrapper title={clubProfileLabels.clubBenefitsTextTooltip}>
                      <Box component="span">
                        <IconButton
                          aria-label="info icon"
                          className={classes.infoIcon}
                          tabIndex="-1"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Box>
                    </TooltipWrapper>
                  </Box>}
                  {showClubIncentives && clubProfileLabels && <Grid
                    className={clsx(classes.incentivesGridItem, classes.gridItem)}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <Box className={classes.incentivesItem}>
                      <ClubFundsCard
                        club={club || {}}
                        labels={clubProfileLabels}
                      />
                    </Box>
                    <TshirtsCard
                      club={club || {}}
                      labels={clubProfileLabels}
                      filteredStudentsCount={filteredStudentsCount}
                    />
                  </Grid>}
                  {isEditable && programTypeDetails?.programPreferencesEnabled && <Grid
                    className={classes.gridItem}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <PreferencesCard
                      club={club || {}}
                      updatePreferences={updatePreferences}
                      isUpdatingPreferences={isUpdatingPreferences}
                      labels={clubProfileLabels}
                    />
                  </Grid>}
                </Grid>
              </Box>
            )}
          </Container>
        ) : (
          <EditClubInfo
            program={club}
            onSave={updateClub}
            communityPartners={communityPartners}
            onCloseModal={() => {
              setIsEditMode(false);
            }}
            countryId={user?.countryId}
            programTypeDetails={programTypeDetails}
          />
        )}
        {inviteModalOpened && (
          <InviteFacilitatorsModal
            isOpen={inviteModalOpened}
            role={currentRole}
            user={user}
            club={club}
            programs={programs}
            currentClubMembers={clubMembers}
            loadProgramMembers={loadProgramMembers}
            programMembers={programMembers}
            isLoadingProgramMembers={isLoadingProgramMembers}
            onCloseModal={() => {
              setInviteModalOpened(false);
            }}
            sendUserInvitation={sendUserInvitation}
            showSuccessMessage={showSuccessMessage}
            showErrorMessage={showErrorMessage}
            createBulkFacilitatorRoles={userRolesApi.createBulkFacilitatorRoles}
            getMembers={getMembers}
            getClubInfo={getClubInfo}
            defaultProgram={defaultProgram}
          />
        )}
        {addLeadershipModalOpened && (
          <AddLeadershipModal
            isOpen={addLeadershipModalOpened}
            user={user}
            club={club}
            loadProgramMembers={loadProgramMembers}
            programMembers={programMembers}
            isLoadingProgramMembers={isLoadingProgramMembers}
            onCloseModal={() => {
              setAddLeadershipModalOpened(false);
            }}
            createBulkCollegeLoopPresidentsRoles={userRolesApi.createBulkCollegeLoopPresidentsRoles}
            getMembers={getMembers}
            getClubInfo={getClubInfo}
            defaultProgram={defaultProgram}
          />
        )}
        {editedUserRoleLabel && roleLabels.length && (
          <EditUserRoleLabelModal
            isOpen={!!editedUserRoleLabel}
            roleLabels={roleLabels}
            editedUserRoleLabel={editedUserRoleLabel}
            programId={club?.id}
            onCloseModal={() => {
              setEditedUserRoleLabel(null);
            }}
            createUserRoleLabel={createUserRoleLabel}
            isLoading={isLoadingUserRoleLabels}
            deleteUserRoleLabel={deleteUserRoleLabel}
          />
        )}
      </main>
      <Footer />
    </div>
  );
};

export default connectTo(
  (state) => {
    return {
      contentful: state.contentful,
      ...state.clubProfile,
      ...trackingActions,
      calendarData: state.calendar,
      members: state.members,
      auth: state.auth,
      studentView: state.global.studentView,
    };
  },
  { push: pushToRoute, ...actions, ...trackingActions, ...calendarActions, ...membersActions,  ...notificationActions },
  ClubProfile
);
