import React, {useEffect, useState} from "react";
import { Form, Formik } from 'formik';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import * as colors from "../../../theme/colors";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import TextCustomButton from '../../../components/text-button/text-button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import CustomButton from '../../../components/customButton/customButton';
import { Introduction } from "./introduction";
import { Child } from "./childInfo";
import { ChildLogin } from "./childLogin";
import { ProgramCode } from "./programInfo";
import { Enroll } from "./enroll";
import { omit } from 'lodash';
import { PROGRAM_TYPES } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  childContainer: {
    display: "flex",
    alignItems: "center",
  },
  childBox: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
  },
  imageContainer: {
    color: colors.darkThemeBlueGray,
  },
  initials: {
    color: colors.darkThemeBlueGray,
    fontSize: 14,
  },
  stepItemWrapper: {
    '& .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel': {
      '& .MuiStepIcon-root': {
        color: colors.grayTheme,
      }
    },
  },
  stepLabel: {
    fontSize: 14,
    fontWeight: 400,
    textAlign: "center",
    padding: "0 20px 0 20px",
  },
  stepLabelWrapper: {
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      color: colors.grayTheme,
    }
  },
  stepWrapper: {
    '& .MuiStep-alternativeLabel': {
      width: 70,
    },
    '& .MuiStepper-root': {
      padding: 0,
    },
    '& .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel': {
      '& .MuiStepIcon-root': {
        width: 30,
        height: 30,
      }
    },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      marginTop: 5,
    },
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      color: colors.darkThemeGreen,
    },
    '& .MuiStepIcon-active': {
      color: colors.darkThemeGreen,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: "100%",
  },
  backBtn: {
    lineHeight: 1.5,
  },
  submitBtn: {
    minWidth: 120,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    },
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: "0 20px 20px 0",
    '& .MuiButton-startIcon': {
      margin: 0
    }
  },
  registeringWrapper: {
    [theme.breakpoints.up('sm')]: {
      width: 500,
    },
  },
  registeringTitle: {
    fontSize: 24,
    textAlign: "center",
    textTransform: "uppercase",
    textWrap: "nowrap",
    fontWeight: 700,
    lineHeight: 1.3,
    color: colors.darkThemeBlueGray,
    padding: "20px 0 20px 0"
  }
}));

const defaultStep = -1;

export const ChildRegistering = ({
  user,
  createTrackingLog,
  joinClub,
  createChild,
  isCreatingChild,
  childRegisteringContent,
  defaultProgramId,
  allProgramTypes,
}) => {
  const classes = useStyles();
  const initialValues = {
    birthDate: '',
    password: '',
    firstName: '',
    lastName: '',
    programCode: '',
    kinshipId: 1,
    email: '',
    username: '',
  };

  const SUBMIT_DEBOUNCE_TIME = 1000;

  const [activeStep, setActiveStep] = useState(defaultStep);
  const [values, setValues] = useState(initialValues);
  const [isEmail, setIsEmail] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [programApproved, setProgramApproved] = useState(false);
  const [programToEnroll, setProgramToEnroll] = useState(null);
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [showResultStep, setShowResultStep] = useState(false);

  const steps = ["Child Info", "Child Login", "Program Info", "Enroll"];

  useEffect(() => {
    if (showResultStep === true) {
      setTimeout(() => {
        setIsSubmitted(false);
      }, SUBMIT_DEBOUNCE_TIME);
    }
  }, [showResultStep]);

  const changeStep = (value) => {
    setValidateOnChange(false)
    setActiveStep(value);
  }

  const getStepContent = (activeStep, changeStep, values, footer, childRegisteringContent) => {
    switch (activeStep) {
      case -1:
        return (
          <Introduction />
        );
      case 0:
        return (
          <Child
            childRegisteringContent={childRegisteringContent}
            footer={footer}
            parentCountryId={String(user.countryId)}
          />
        );
      case 1:
        return (
          <ChildLogin
            childRegisteringContent={childRegisteringContent}
            user={user}
            footer={footer}
            setIsEmail={setIsEmail}
            isEmail={isEmail}
          />
        );
      case 2:
        return (
          <ProgramCode
            setPage={changeStep}
            programCode={values?.programCode}
            programToEnroll={programToEnroll}
            type={programToEnroll?.programType}
            footer={footer}
            createTrackingLog={createTrackingLog}
            defaultProgramId={defaultProgramId}
            setProgramToEnroll={setProgramToEnroll}
            parentCountryId={user?.countryId}
            showResultStep={showResultStep}
            allProgramTypes={allProgramTypes}
          />
        );
      case 3:
        return (
          <Enroll
            type={programToEnroll?.programType}
            enrollmentForm={joinClub?.enrollmentForm}
            enrollmentData={joinClub?.enrollmentData}
            createTrackingLog={createTrackingLog}
            joinClub={joinClub}
            footer={footer}
            user={user}
          />
        )
      default:
        break;
    }
  };

  const ref = React.useRef(null);
  const getNextBtnLabel = (step, program) => {
    switch (step) {
      case 2:
        return (!program || !showResultStep) ? 'Search' : 'This looks right';
      case 3:
        return 'Complete registration';
      default:
        return 'Continue';
    } 
  }

  const createChildHandler = (data) => {
    createChild(data);
  };

  const getFooter = () => (
    <Box className={`${classes.btnWrapper} ${classes[`btnWrapper${activeStep}`]}`}>
      <TextCustomButton
        className={classes.backBtn}
        mode="menuItem"
        startIcon={<ArrowBackIosIcon />}
        disabled={isSubmitted}
        onClick={() => {
          setIsSubmitted(true);
          setTimeout(() => {
            setIsSubmitted(false);
          }, SUBMIT_DEBOUNCE_TIME);
          if (activeStep === 2 && programToEnroll) {
            setProgramApproved(null);
            setProgramToEnroll(null);
            setShowResultStep(false);
            return;
          }
          changeStep(activeStep - 1)
        }}
        label="Back"
      />
      <CustomButton
        mode="primary"
        type="submit"
        disabled={isSubmitted || isCreatingChild || programToEnroll?.programType === PROGRAM_TYPES.COMMUNITY_PARTNERSHIP}
        className={classes.submitBtn}
        label={getNextBtnLabel(activeStep, programToEnroll)}
      />
    </Box>)


  return (
    <Box className={classes.registeringWrapper}>
      <Typography className={classes.registeringTitle}>
        Register a child
      </Typography>
      <Box className={classes.wrapper}>
        <Formik
          initialValues={values}
          validateOnMount={false}
          validateOnChange={validateOnChange}
          validate={() => {
            setValidateOnChange(true);
          }}
          onSubmit={(submittedValues) => {

            //Do not send second request
            if (isSubmitted) {
              setTimeout(() => {
                setIsSubmitted(false);
              }, SUBMIT_DEBOUNCE_TIME);
              return false;
            }

            setIsSubmitted(true);
            setValues(submittedValues);
            const { firstName, lastName, birthDate, kinshipId, email, username } = submittedValues;
            const child = { firstName, lastName, birthDate, kinshipId, email, username };

            if (activeStep === 2 && !programApproved) {
              setShowResultStep(true);
              setProgramApproved(true);
              return;
            }
            if (activeStep === 3) {
              if (!isEmail) {
                child.username = child.email;
                delete child.email;
              } else {
                delete child.username;
              }
              createChildHandler(
                {
                  childInfo: {
                    ...child,
                    userId: user.id,
                    kinshipId: submittedValues?.kinshipId
                  },
                  childPassword: submittedValues?.password,
                  program : {
                    code: submittedValues?.programCode
                  },
                  enrollmentData: omit(submittedValues, Object.keys(initialValues))
                }
              )
              return;
            }
            setTimeout(() => {
              setIsSubmitted(false);
            }, SUBMIT_DEBOUNCE_TIME);

            changeStep(activeStep + 1);
          }}
        >{() => (
          <Form ref={ref}>
            <Box>
              <Box className={classes.stepWrapper}>
                <Stepper activeStep={activeStep} alternativeLabel={true}>
                  {steps.map((label, stepIndex) => (
                    <Step key={label} completed={false} className={`${stepIndex < activeStep ? classes.stepItemWrapper : ''}`} >
                      <StepLabel className={`${stepIndex < activeStep ? clsx(classes.stepLabelWrapper, classes.stepLabel) : classes.stepLabel}`}> {label} </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              {getStepContent(
                activeStep,
                changeStep,
                values,
                getFooter(isCreatingChild),
                childRegisteringContent)}
            </Box>
          </Form>
        )}
        </Formik>
      </Box>
    </Box>
  );
};

