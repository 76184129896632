import { createReducer } from 'redux-act';
import * as a from '../actions/global';

const getDefaultState = () => ({
  currentPrograms: null,
  initialLoadersCount: 0,
  programs: [],
  isMobile: false,
  staticPageContent: null,
  studentView: false,
});

export default () =>
  createReducer({
    [a.setMenuVisibility]: (state) => ({
      ...state,
    }),
    [a.incrementInitialLoaders]: (state) => ({
      ...state,
      initialLoadersCount: state.initialLoadersCount + 1,
    }),
    [a.decrementInitialLoaders]: (state) => ({
      ...state,
      initialLoadersCount: state.initialLoadersCount === 0 ? 0 : state.initialLoadersCount - 1,
    }),
    [a.setIsMobile]: (state, isMobile) => {
      return {
        ...state,
        isMobile
      };
    },
    [a.setDefaultState]: () => {
      return getDefaultState();
    },
    [a.receivedStaticPagesContent]: (state, content) => ({
      ...state,
      staticPageContent: content
    }),
    [a.setStudentView]: (state, studentView) => {
      return {
        ...state,
        studentView
      };
    },  
  },
    getDefaultState());
